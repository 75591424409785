import { useEffect, useRef } from 'react';
import { LocalDataTrack } from 'twilio-video';

const dataTrack = new LocalDataTrack();

function useDataTrack(room) {
    const isDataTrackPublished = useRef({}).current;
    if (isDataTrackPublished.promise) {
        isDataTrackPublished.promise = new Promise((resolve, reject) => {
            isDataTrackPublished.resolve = resolve;
            isDataTrackPublished.reject = reject;
        });
    }

    useEffect(() => {
        if (room) {
            if (
                room.localParticipant.tracks.find(
                    (track) => track === dataTrack && dataTrack.isPublished
                )
            ) {
                isDataTrackPublished.promise.resolve();
            }
        }
    }, [room]);

    async function emitEvent(participant, eventKind, eventMessage) {
        console.log('about to send...');
        let message;
        if (eventMessage !== null && eventMessage !== undefined) {
            message = eventMessage;
        } else {
            message = {
                eventKind: eventKind,
                params: {
                    identity: participant.identity,
                },
            };
        }
        await isDataTrackPublished.promise;
        console.log('sending', message);
        dataTrack.send(JSON.stringify(message));
    }

    useEffect(() => {
        if (!room) return;
        room.localParticipant.on('trackPublished', (publication) => {
            if (publication.track === dataTrack) {
                isDataTrackPublished.promise.resolve();
            }
        });

        room.localParticipant.on('trackPublicationFailed', (error, track) => {
            if (track === dataTrack) {
                isDataTrackPublished.promise.reject(error);
            }
        });
    }, [room]);

    return emitEvent;
}

export { dataTrack };
export default useDataTrack;
