import { useState, useEffect } from 'react';
import useRoom from './useRoom';
import useEvents from './useEvents';

const INITIAL_PUBLICATIONS = [];
const INITIAL_TRACKS = [];
const INITIAL_DATA_TRACK = null;

export default function useTracks(participant, subscribeToCustomEvents = false, audioOnly=false) {
    const [publications, setPublications] = useState(INITIAL_PUBLICATIONS);
    const [tracks, setTracks] = useState(INITIAL_TRACKS);
    const [dataTrack, setDataTrack] = useState(INITIAL_DATA_TRACK);
    const { room } = useRoom();
    const { send } = useEvents();

    function trackAdded(track) {
        if (track.kind === 'data' && subscribeToCustomEvents) {
            setDataTrack(track);
        }
        if(audioOnly && track.kind === 'video') {
            return;
        }
        setTracks((prevTracks) => [...prevTracks, track]);
    }

    function trackRemoved(track) {
        setTracks((prevTracks) => prevTracks.filter((t) => t !== track));
    }

    function publicationAdded(publication) {
        const isLocal = Array.from(room.localParticipant.tracks.values()).find(
            (p) => p === publication
        );
        if (isLocal) {
            trackAdded(publication.track);
        }
        setPublications((prevPublications) => [...prevPublications, publication]);
    }

    function publicationRemoved(publication) {
        return setPublications((prevPublications) => {
            return prevPublications.filter((p) => p !== publication);
        });
    }

    function handleMessage( message) {
        send(message);
    }

    useEffect(() => {
        if (dataTrack) {
            dataTrack.on('message', handleMessage);
            return () => dataTrack.off('message', handleMessage);
        }
    }, [dataTrack]);

    useEffect(() => {
        if (participant) {
            setPublications(Array.from(participant.tracks?.values() || []));
            const tracks = Array.from(participant.tracks?.values())
                .map((p) => p.track)
                .filter((t) => t);
            setTracks(tracks);
            if (subscribeToCustomEvents) {
                const dataTrack = tracks.find((t) => t.kind === 'data');
                if (dataTrack) {
                    setDataTrack(dataTrack);
                }
            }
            if (room?.localParticipant === participant) {
                participant.on('localTrackUnsubscribed', trackRemoved);
                participant.on('localTrackUnpublished', publicationRemoved);
            }
            participant.on('trackSubscribed', trackAdded);
            participant.on('trackUnsubscribed', trackRemoved);
            participant.on('trackPublished', publicationAdded);
            participant.on('trackUnpublished', publicationRemoved);
            return () => {
                if (room?.localParticipant === participant) {
                    participant.off('localTrackUnpublished', publicationRemoved);
                    participant.off('localTrackUnsubscribed', trackRemoved);
                }
                participant.off('trackSubscribed', trackAdded);
                participant.off('trackUnsubscribed', trackRemoved);
                participant.off('localTrackUnsubscribed', trackRemoved);
                participant.off('trackPublished', publicationAdded);
                participant.off('trackUnpublished', publicationRemoved);
            };
        }
    }, [participant, room]);

    return { publications, tracks };
}
