import React, { useEffect } from 'react';
import withRoomDetails from '../../hoc/withRoomDetails';
import { room$ } from '../../services/subjects';
import { connect, createLocalTracks } from 'twilio-video';
import { isAudioAppointment, isMobile as isMobileAgent } from '../../common/utility';
import { internalRoomStatusesMap } from '../../common/constants';
import withFetchOrCreateRoomAndToken from '../../hoc/withFetchOrCreateRoomAndToken';
import withProviderAuthTokenFromParams from '../../hoc/withProviderAuthTokenFromParams';
import withTranslatorLink from '../../hoc/withTranslatorLink';
import useRoom from '../../hooks/useRoom';
import RoomBody from './RoomBody';
import { dataTrack } from '../../hooks/useDataTrack';
import styles from './ProviderVirtualRoom.module.scss';
import LoadingScreen from './LoadingScreen';
function ProviderVirtualRoom(props) {
    const { appointmentDetails, roomCreated, roomToken, roomError, translatorLink } = props;
    const { room, roomStatus } = useRoom();
    function getVideoSettings() {
        if (isMobileAgent()) {
            return { height: 480, frameRate: 24, width: 640 };
        } else {
            return { height: 720, frameRate: 24, width: 1280 };
        }
    }

    async function connectToRoom() {
        try {
            const tracks = await createLocalTracks({
                audio: true,
                video: isAudioAppointment(appointmentDetails) ? undefined : { facingMode: 'user' },
            });
            const room = await connect(roomToken, {
                name: appointmentDetails.id,
                tracks: [...tracks, dataTrack],
                preferredVideoCodecs: ['H264'],
                dominantSpeaker: true,
                video: getVideoSettings(),
                maxAudioBitrate: 16000,
                networkQuality: {
                    local: 3,
                    remote: 3,
                },
            });
            room$.next({ room, roomInternalStatus: internalRoomStatusesMap.CONNECTED });
            room.on('disconnected', handleDisconnect);
        } catch (e) {
            /** todo implement handler */
            console.log('An error has ocurred ->', e);
        }
    }

    function handleDisconnect(room) {
        room.localParticipant.tracks.forEach((publication) => {
            if (publication.track.kind === 'data') {
                return;
            }
            publication.track && publication.track.stop();
            const attachedElements = publication.track.detach();
            attachedElements.forEach((element) => element.remove());
        });

        room && room.off('disconnected', handleDisconnect);
    }

    function handleLeave() {
        if (room) {
            room.disconnect();
        }

        room$.next({ room: null, roomInternalStatus: internalRoomStatusesMap.CALL_ENDED });
    }

    useEffect(() => {
        if (appointmentDetails && roomCreated && roomToken) {
            connectToRoom();
            if (isMobileAgent()) {
                window.addEventListener('pagehide', handleLeave);
            }
            window.addEventListener('beforeunload', handleLeave);
        }
    }, [appointmentDetails, roomCreated, roomToken]);

    useEffect(() => {
        return () => {
            if (isMobileAgent()) {
                window.removeEventListener('pagehide', handleLeave);
            }
            window.removeEventListener('beforeunload', handleLeave);
        };
    }, []);
    if (roomError) {
        return (
            <section className={styles['provider-container']}>
                <h3>
                    We are sorry a problem has occurred connecting to room, make sure the room
                    exists or your identity is valid
                </h3>
            </section>
        );
    }

    if (roomStatus === internalRoomStatusesMap.CALL_ENDED) {
        return (
            <section className={styles['provider-container']}>
                <h3>You hanged up the call</h3>
            </section>
        );
    }

    return room ? (
        <RoomBody
            onLeave={handleLeave}
            room={room}
            appointment={appointmentDetails}
            translatorLink={translatorLink}
        />
    ) : (
        <LoadingScreen />
    );
}

export default withProviderAuthTokenFromParams(
    withRoomDetails(withFetchOrCreateRoomAndToken(withTranslatorLink(ProviderVirtualRoom)))
);
