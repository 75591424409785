import { isAudioAppointment } from '../../common/utility';
import React, { useRef, useState } from 'react';
import useRoom from '../../hooks/useRoom';
import useParticipants from '../../hooks/useParticipants';
import useTracks from '../../hooks/useTracks';
import RoomActions from './RoomActions';
import RoomInfo from './RoomInfo';
import Participants from './Participants';
import styles from './RoomBody.module.scss';
import { createLocalVideoTrack } from 'twilio-video';
import { copyText } from '../../common/constants';
import Toaster from '../Toaster/Toaster';
function RoomBody({ appointment, translatorLink, onLeave }) {
    const [selfMuted, setSelfMuted] = useState(false);
    const [selfVideoOff, setSelfVideoOff] = useState(false);
    const { room } = useRoom();
    const { participants } = useParticipants(appointment, false);
    const [show, setShow] = useState(false);
    async function handleChanceCamera() {
        if (selfVideoOff) {
            const videoTrack = await createLocalVideoTrack();
            room.localParticipant.publishTrack(videoTrack);
            setSelfVideoOff(false);
            return;
        }
        room.localParticipant.tracks.forEach((publication) => {
            if (publication.track?.kind === 'video') {
                room.localParticipant.emit('localTrackUnpublished', publication);
                room.localParticipant.emit('localTrackUnsubscribed', publication.track);
                publication.track.stop();
                publication.unpublish();
                setSelfVideoOff(true);
            }
        });
    }

    function handleCopy() {
        if (translatorLink) {
            const fullTranslatorLink = `${window.location.protocol}//${window.location.host}${translatorLink}`;
            navigator.clipboard.writeText(fullTranslatorLink);
            setShow(true);
            return true;
        }
        return false;
    }

    function handleCopyInvite(participant) {
        const connectionId = getParticipantConnectionId(participant.identity);
        if (connectionId) {
            const fullTranslatorLink = `${window.location.protocol}//${window.location.host}/${appointment.id}/${connectionId}`;
            navigator.clipboard.writeText(fullTranslatorLink);
            setShow(true);
            return true;
        }
        return false;
    }

    function getParticipantConnectionId(identity) {
        if (identity === appointment.memberConnectionId) {
            return appointment.memberConnectionId;
        }

        const attendee = appointment.optionalAttendees.find((optionalAttendee) => {
            if (optionalAttendee.connectionId === identity) {
                return true;
            }
        });

        return attendee?.connectionId;
    }

    function handleChangeMicrophone() {
        room.localParticipant.tracks.forEach((publication) => {
            if (publication.track?.kind === 'audio') {
                if (selfMuted) {
                    publication.track.enable();
                    setSelfMuted(false);
                } else {
                    publication.track.disable();
                    setSelfMuted(true);
                }
            }
        });
    }

    return (
        <section className={styles['room-body']}>
            <article className={styles.participants}>
                <Participants
                    participants={participants}
                    localParticipant={room?.localParticipant}
                    appointment={appointment}
                />
                <article className={styles.actions}>
                    <RoomActions
                        onLeave={onLeave}
                        appointment={appointment}
                        participant={room?.localParticipant}
                        onChangeMicrophone={handleChangeMicrophone}
                        onChangeCamera={handleChanceCamera}
                        onCopy={handleCopy}
                        translatorLink={translatorLink}
                    />
                </article>
            </article>
            <article className={styles.roomInfo}>
                <RoomInfo
                    localParticipant={room?.localParticipant}
                    appointment={appointment}
                    participants={participants.filter((p) => p)}
                    onCopyInvite={handleCopyInvite}
                />
                <Toaster show={show} displayText={copyText} onCloseAction={() => setShow(false)} />
            </article>
        </section>
    );
}

RoomBody.displayName = RoomBody.name;

export default RoomBody;
