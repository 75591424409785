import React from 'react';
import PropTypes from 'prop-types';
import { ParticipantPropType } from './Participant';
import ParticipantInfo from './ParticipantInfo';

import styles from './ParticipantsInfo.module.scss';

const ParticipantsInfo = ({ participants, appointment }) => {
    return (
        <div className={styles['participants-info']}>
            <h4>Participants</h4>
            {participants.map((p) => (
                <ParticipantInfo key={p.sid} participant={p} appointment={appointment} />
            ))}
        </div>
    );
};

ParticipantsInfo.displayName = ParticipantsInfo;
ParticipantsInfo.propTypes = {
    participants: PropTypes.arrayOf(ParticipantPropType),
};
ParticipantsInfo.defaultProps = {
    participants: [],
};

export default ParticipantsInfo;
