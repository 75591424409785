'use strict';
var __assign =
    (this && this.__assign) ||
    function () {
        __assign =
            Object.assign ||
            function (t) {
                for (var s, i = 1, n = arguments.length; i < n; i++) {
                    s = arguments[i];
                    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
                }
                return t;
            };
        return __assign.apply(this, arguments);
    };
Object.defineProperty(exports, '__esModule', { value: true });
var React = require('react');
var rtcDiagnostics = require('@twilio/rtc-diagnostics');
var utilities = require('../../utils');
var constants = require('../../utils/constants');
var audioInputTest;
var audioOutputTest;
var getErrorMessage = function (error) {
    var message = 'An unknown error has occurred';
    if (error) {
        message = error.domError ? error.domError.toString() : error.message;
    }
    return message;
};
function useAudioTest() {
    var audioRecordingState = React.useState(false),
        isRecording = audioRecordingState[0],
        setIsRecording = audioRecordingState[1];
    var audioInputTestState = React.useState(false),
        isAudioInputTestRunning = audioInputTestState[0],
        setIsAudioInputTestRunning = audioInputTestState[1];
    var audioOutputTestState = React.useState(false),
        isAudioOutputTestRunning = audioOutputTestState[0],
        setIsAudioOutputTestRunning = audioOutputTestState[1];
    var audioInputLevelState = React.useState(0),
        inputLevel = audioInputLevelState[0],
        setInputLevel = audioInputLevelState[1];
    var audioOutputLevelState = React.useState(0),
        outputLevel = audioOutputLevelState[0],
        setOutputLevel = audioOutputLevelState[1];
    var playbackUrlState = React.useState(''),
        playbackURI = playbackUrlState[0],
        setPlaybackURI = playbackUrlState[1];
    var errorState = React.useState(''),
        error = errorState[0],
        setError = errorState[1];
    var playAudio = React.useCallback(function (options) {
        options = __assign({ doLoop: false }, options);
        audioOutputTest = rtcDiagnostics.testAudioOutputDevice(options);
        setIsAudioOutputTestRunning(true);
        audioOutputTest.on(rtcDiagnostics.AudioOutputTest.Events.Volume, function (value) {
            setOutputLevel(utilities.getAudioLevelPercentage(value));
        });
        audioOutputTest.on(rtcDiagnostics.AudioOutputTest.Events.End, function (report) {
            setIsAudioOutputTestRunning(false);
            setOutputLevel(0);
            var stdDev = utilities.getStandardDeviation(report.values);
            if (stdDev === 0) {
                setError('No audio detected');
            }
        });
        audioOutputTest.on(rtcDiagnostics.AudioOutputTest.Events.Error, function (diagnosticError) {
            setError(getErrorMessage(diagnosticError));
        });
    }, []);
    var readAudioInput = React.useCallback(
        function (options) {
            if (audioInputTest) {
                audioInputTest.stop();
            }
            var duration = options.enableRecording
                ? constants.RECORD_DURATION
                : constants.INPUT_TEST_DURATION;
            options = __assign({ duration: duration }, options);
            audioInputTest = rtcDiagnostics.testAudioInputDevice(options);
            setIsAudioInputTestRunning(true);
            if (options.enableRecording) {
                setIsRecording(true);
            }
            audioInputTest.on(rtcDiagnostics.AudioInputTest.Events.Volume, function (value) {
                setInputLevel(utilities.getAudioLevelPercentage(value));
            });
            audioInputTest.on(rtcDiagnostics.AudioInputTest.Events.End, function (report) {
                if (playbackURI && report.recordingUrl) {
                    URL.revokeObjectURL(playbackURI);
                }
                if (report.recordingUrl) {
                    setPlaybackURI(report.recordingUrl);
                }
                setIsRecording(false);
                setIsAudioInputTestRunning(false);
            });
            audioInputTest.on(
                rtcDiagnostics.AudioInputTest.Events.Error,
                function (diagnosticError) {
                    setError(getErrorMessage(diagnosticError));
                }
            );
            audioInputTest.on(rtcDiagnostics.AudioInputTest.Events.Warning, function (name) {});
            audioInputTest.on(
                rtcDiagnostics.AudioInputTest.Events.WarningCleared,
                function (name) {}
            );
        },
        [playbackURI]
    );
    var stopAudioTest = React.useCallback(function () {
        if (audioInputTest) {
            audioInputTest.stop();
            setInputLevel(0);
        }
        if (audioOutputTest) {
            audioOutputTest.stop();
            setOutputLevel(0);
        }
    }, []);
    return {
        error: error,
        setError: setError,
        isRecording: isRecording,
        isAudioInputTestRunning: isAudioInputTestRunning,
        isAudioOutputTestRunning: isAudioOutputTestRunning,
        playAudio: playAudio,
        playbackURI: playbackURI,
        readAudioInput: readAudioInput,
        stopAudioTest: stopAudioTest,
        inputLevel: inputLevel,
        outputLevel: outputLevel,
    };
}
exports.default = useAudioTest;
