
import {useState, useEffect} from 'react';

export default function useTrackEnabled(tracks, kind='video') {
  const [enabled, setEnabled] = useState(false);

  const publicationEnabled = () => {
    setEnabled(true);
  }
  const publicationDisabled = () => {
    setEnabled(false);
  }
  
  useEffect(() => {
    if(tracks.length) {
        const track = tracks.find(t => t.kind === kind);
        if(track) {
          track.on('enabled', publicationEnabled);
          track.on('disabled', publicationDisabled);
          setEnabled(track.isEnabled)
        } else {
          setEnabled(false);
        }
        
        
      
      return () => {
        if(track) {
            track.off('enabled', publicationEnabled);
            track.off('disabled', publicationDisabled);
        }
        
      };
    }
  }, [tracks, kind]);

  return enabled;
}
