import {useEffect, useState} from 'react';
import {unknownGuests$} from '../services/subjects';

const INITIAL_STATE = [{}, 0];
function useEvents() {
    const [[guests, count], setGuests] = useState(INITIAL_STATE)

    useEffect(() => {
        const subscription = unknownGuests$.subscribe(([nextGuest, nextCount]) => {
            setGuests([nextGuest, nextCount])
        });
        return () => {
            return subscription.unsubscribe();
        }
    }, [])
    
    return {guests, count}
}

export default useEvents;