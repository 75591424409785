const MEDIA_ACCESS_GRANTED = 'mediaAccessGranted';
const GRANTED = 'granted';

/**
 * Request microphone and camera access if it is still no granted.
 * If permissions are graneted, it saves the flag `mediaAccessGranted` in sessionStorage to avoid ask for permissions again.
 */
const requestPermissions = async () => {
    const { microphone, camera } = await verifyPermissions();
    if (microphone === GRANTED && camera === GRANTED) {
        setMediaAccessGranted();
        return;
    }
    try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        setMediaAccessGranted();
    } catch (error) {
        console.error('Error accessing camera and microphone:', error);
    }
};

const verifyPermissions = async () => {
    try {
        const microphone = await navigator.permissions.query({ name: 'microphone' });
        const camera = await navigator.permissions.query({ name: 'camera' });
        return { microphone: microphone.state, camera: camera.state };
    } catch (error) {
        console.log(error);
    }
};

const setMediaAccessGranted = () => {
    const expiration = new Date().getTime() + 1800 * 1000;
    sessionStorage.setItem(MEDIA_ACCESS_GRANTED, JSON.stringify({ value: true, expiration }));
};

/**
 * Verify if the user already grant access to microphone and camera by reading the flag `mediaAccessGranted` from sessionStorage.
 */
const isMediaAccessGranted = () => {
    const item = sessionStorage.getItem(MEDIA_ACCESS_GRANTED);
    if (!item) {
        return false;
    }

    const { value, expiration } = JSON.parse(item);
    const currentTime = new Date().getTime();
    if (currentTime > expiration) {
        sessionStorage.removeItem(MEDIA_ACCESS_GRANTED);
        return false;
    }

    return value;
};

export { requestPermissions, isMediaAccessGranted };
