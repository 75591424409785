import {useState} from 'react';
import {events$} from '../services/subjects';

const INITIAL_LAST_EVENT = null;
function useEvents() {
    const [lastEvent, setLastEvent] = useState(INITIAL_LAST_EVENT)

    function send(event) {
        event = JSON.parse(event);
        events$.next(event);
    }

    function subscribe() {
        return events$.subscribe({
            next: nextEvent => {
                setLastEvent(nextEvent);
            }
        });
    }

    return {send, subscribe, lastEvent}
}

export default useEvents;