export const AUDIO_LEVEL_THRESHOLD = 200;

export const AUDIO_LEVEL_STANDARD_DEVIATION_THRESHOLD = AUDIO_LEVEL_THRESHOLD * 0.05;

export const INPUT_TEST_DURATION = 20000;

export const RECORD_DURATION = 4000;

export const noAudioDetected = 'No audio detected';

export const unableToDetect = 'Unable to connect.';
