import { AUDIO_LEVEL_THRESHOLD } from './constants';

export function getAudioLevelPercentage(level) {
    return (level * 100) / AUDIO_LEVEL_THRESHOLD;
}
// The variables used here calculate quality of the data transfer between twilio and the client. They are being consumed on the audio device test.
export function getStandardDeviation(values) {
    if (values.length <= 0) {
        return 0;
    }

    const valueAverage =
        values.reduce((partialSum, value) => partialSum + value, 0) / values.length;

    const diffSquared = values.map((value) => Math.pow(value - valueAverage, 2));

    const stdDev = Math.sqrt(
        diffSquared.reduce((partialSum, value) => partialSum + value, 0) / diffSquared.length
    );

    return round(stdDev);
}

export const round = (num, decimals = 2) =>
    Math.round((num + Number.EPSILON) * 10 ** decimals) / 10 ** decimals;
