// The source of this file is located in AHC.Packages/conversation-room/src
// If you are making changes not at the source they will be overridden
// The file is being copied using a script in the CC-vNext package.json
// This is a temporary solution - there was a weird issue with babel/webpack and npm link

import React, { useState, useEffect } from 'react';
import MessageRoom from './MessageRoom';
import { Client } from '@twilio/conversations';

const Conversations = require('@twilio/conversations');

interface ConversationRoomProps {
    conversationsToken: string | null;
    conversationFriendlyName?: string;
    conversationAttributes?: object;
    conversationSid: string | null;
    handleTokenAboutToExpire: () => void;
    messageBoxStyle?: object;
    onConversationCreate?: (conversation: any) => void;
    onConversationJoin?: (conversation: any) => void;
    participantName: string;
    participantIdentity: string;
    showLeaveButton?: boolean;
    uploadMessageFile: (file: any) => Promise<any>;
}

const ConversationRoom: React.FC<ConversationRoomProps> = (props) => {
    const [client, setClient] = useState<Client | null>(null);
    const [conversation, setConversation] = useState<any>(null);

    useEffect(() => {
        if (props.conversationsToken && !client) {
            Conversations.Client.create(props.conversationsToken).then((client: Client) => {
                setClient(client);
                client.on('tokenAboutToExpire', () => {
                    props.handleTokenAboutToExpire();
                });
            });
        }
        if (props.conversationsToken && client) {
            client.updateToken(props.conversationsToken);
        }
    }, [props.conversationsToken]);

    useEffect(() => {
        if (props.conversationSid && !conversation && client) {
            client.getConversationBySid(props.conversationSid).then((conversation: any) => {
                setConversation(conversation);
                if (typeof props.onConversationJoin === 'function') {
                    props.onConversationJoin(conversation);
                }
            });
        }
    }, [props.conversationSid, client]);

    const startConversation = () => {
        if (props.conversationSid && client) {
            client.getConversationBySid(props.conversationSid).then((conversation: any) => {
                setConversation(conversation);
                if (typeof props.onConversationJoin === 'function') {
                    props.onConversationJoin(conversation);
                }
            });
        } else if (!props.conversationSid && client) {
            client
                .createConversation({
                    friendlyName: props.conversationFriendlyName,
                    attributes: props.conversationAttributes,
                })
                .then((conversation) => {
                    setConversation(conversation);
                    if (typeof props.onConversationCreate === 'function') {
                        props.onConversationCreate(conversation);
                    }
                });
        }
    };

    if (!client) {
        return null;
    }

    return (
        <div>
            {conversation ? (
                <MessageRoom
                    conversation={conversation}
                    participantName={props.participantName}
                    participantIdentity={props.participantIdentity}
                    showLeaveButton={props.showLeaveButton}
                    messageBoxStyle={props.messageBoxStyle}
                    uploadMessageFile={props.uploadMessageFile}
                />
            ) : (
                <button onClick={startConversation} style={{ width: '200px' }}>
                    Start Text Chat
                </button>
            )}
        </div>
    );
};

export default ConversationRoom;
