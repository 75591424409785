import React from 'react';
import useTracks from '../../hooks/useTracks';
import useTrackEnabled from '../../hooks/useTrackEnabled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import { isAudioAppointment } from '../../common/utility';
import useUnkownGuests from '../../hooks/useUnknownGuests';

import styles from './ParticipantInfo.module.scss';
import useDataTrack from '../../hooks/useDataTrack';


function ParticipantInfo({ participant, appointment, localParticipant, onCopyInvite }) {
    const {guests} = useUnkownGuests();
    const { tracks: participantTracks } = useTracks(participant, false, isAudioAppointment(appointment));
    const isAudioTrackEnabled = useTrackEnabled(participantTracks, 'audio');
    const emit = useDataTrack();

    function getParticipantName(identity) {
        if (identity === appointment.memberConnectionId) {
            return appointment.memberFullName;
        }

        const attendee = appointment.optionalAttendees.find((oa) => {
            if (oa.connectionId === identity) {
                return true;
            }
        });
        
        if(attendee) {
            return attendee?.fullName 
        }

        if(/(pcp)|(virtual provider)/i.test(identity)) {
            return identity
        }

        return guests[identity] ||'Unknown Guest';
    }

    function handleSilentParticipant() {
        console.log('muting participant', getParticipantName(participant.identity));
        emit(participant, 'mute');
    }

    function isParticipantMe() {
        return localParticipant === participant;
    }
    
    return (
        <div className={styles.participant}>
            {getParticipantName(participant.identity)}
            <div>
            {(!isParticipantMe() || null) &&<FontAwesomeIcon
                onClick={!isAudioTrackEnabled ? undefined : handleSilentParticipant}
                className={styles.icon}
                icon={isAudioTrackEnabled ? faMicrophone : faMicrophoneSlash}
            />}
            </div>
        </div>
    );
}

export default ParticipantInfo;
