import React, { useEffect } from 'react';
import { providerAuthToken$ } from '../services/subjects';

export default function withProviderAuthTokenFromParams(WrappedComponent) {
    return (props) => {
        const providerAuthToken = window.token;
        useEffect(() => {
            providerAuthToken$.next(providerAuthToken);
        }, [providerAuthToken]);
        return <WrappedComponent {...props} providerAuthToken={providerAuthToken} />;
    };
}
