'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
exports.AudioDevice = void 0;
var React = require('react');
var react_1 = require('react');
var core_1 = require('@material-ui/core');
var useDevices_1 = require('../../../../hooks/useDevices');
var constants_1 = require('../../utils/constants');
var labels = {
    audioinput: {
        audioLevelText: 'Input level',
        headerText: 'Microphone',
    },
    audiooutput: {
        audioLevelText: 'Output level',
        headerText: 'Speaker',
    },
};
var useStyles = core_1.makeStyles({
    form: {
        margin: '0.5em 0 1em',
    },
    deviceLabelContainer: {
        margin: '0.8em 0',
    },
    deviceList: {
        height: '36px',
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1em',
        '& svg': {
            marginRight: '0.3em',
        },
    },
});
function AudioDevice(_a) {
    var disabled = _a.disabled,
        kind = _a.kind,
        onDeviceChange = _a.onDeviceChange,
        setDeviceError = _a.setDeviceError,
        error = _a.error;
    var classes = useStyles();
    var devices = useDevices_1.default();
    var audioDevices =
        kind === 'audiooutput' ? devices.audioOutputDevices : devices.audioInputDevices;
    var _b = react_1.useState(''),
        selectedDevice = _b[0],
        setSelectedDevice = _b[1];
    var headerText = labels[kind].headerText;
    var noAudioRedirect = !Audio.prototype.setSinkId && kind === 'audiooutput';
    var updateSelectedDevice = react_1.useCallback(
        function (value) {
            onDeviceChange(value);
            setSelectedDevice(value);
            setDeviceError('');
        },
        [onDeviceChange, setSelectedDevice, setDeviceError]
    );
    react_1.useEffect(
        function () {
            var hasSelectedDevice = audioDevices.some(function (device) {
                return device.deviceId === selectedDevice;
            });
            if (audioDevices.length && !hasSelectedDevice) {
                updateSelectedDevice(audioDevices[0].deviceId);
            }
        },
        [audioDevices, devices, selectedDevice, updateSelectedDevice]
    );
    return React.createElement(
        React.Fragment,
        null,
        React.createElement(
            core_1.Typography,
            { variant: 'subtitle2' },
            React.createElement('strong', null, headerText)
        ),
        noAudioRedirect &&
            React.createElement(
                'div',
                { className: classes.deviceLabelContainer },
                React.createElement('p', null, 'System Default Audio Output')
            ),
        !noAudioRedirect &&
            React.createElement(
                core_1.FormControl,
                {
                    variant: 'outlined',
                    disabled: disabled,
                    className: classes.form,
                    fullWidth: true,
                },
                React.createElement(
                    core_1.Select,
                    {
                        value: selectedDevice,
                        onChange: function (e) {
                            return updateSelectedDevice(e.target.value);
                        },
                        className: classes.deviceList,
                    },
                    audioDevices.map(function (device) {
                        return React.createElement(
                            core_1.MenuItem,
                            { value: device.deviceId, key: device.deviceId },
                            device.label
                        );
                    })
                )
            ),
        error &&
            React.createElement(
                'div',
                { className: classes.error },
                React.createElement(
                    core_1.Typography,
                    { variant: 'subtitle2', color: 'error' },
                    error === constants_1.noAudioDetected
                        ? constants_1.noAudioDetected + '.'
                        : constants_1.unableToDetect
                )
            )
    );
}
exports.AudioDevice = AudioDevice;
exports.default = React.memo(AudioDevice);
