import React, { useEffect, useRef, useState } from 'react';
import { Button, Paper, Typography, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AudioDevice from './AudioDevice/AudioDevice';
import ProgressBar from './ProgressBar/ProgressBar';
import useAudioTest from './useAudioTest/useAudioTest';
import Microphone from '../utils/icons/Microphone';
import Speaker from '../utils/icons/SpeakerIcon';

import styles from '../TelehealthTest.module.scss';

const useStyles = makeStyles({
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    audioLevelContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '2.5em',
    },
    topLine: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
});

export function AudioTest() {
    const classes = useStyles();
    const [inputDeviceId, setInputDeviceId] = useState('');
    const [outputDeviceId, setOutputDeviceId] = useState('');
    const previousInputDeviceIdRef = useRef('');

    const {
        error,
        setError,
        isRecording,
        isAudioInputTestRunning,
        isAudioOutputTestRunning,
        playAudio,
        playbackURI,
        readAudioInput,
        stopAudioTest,
        inputLevel,
        outputLevel,
    } = useAudioTest();

    const volumeLevel = isAudioOutputTestRunning ? outputLevel : inputLevel;

    const disableAll =
        isRecording || isAudioOutputTestRunning || (!!error && error !== 'No audio detected');

    const handleRecordClick = () => {
        readAudioInput({ deviceId: inputDeviceId, enableRecording: true });
    };

    const handlePlayClick = () => {
        playAudio({ deviceId: outputDeviceId, testURI: playbackURI });
    };

    useEffect(() => {
        const newInputDeviceSelected = previousInputDeviceIdRef.current !== inputDeviceId;
        previousInputDeviceIdRef.current = inputDeviceId;

        if (!error && (newInputDeviceSelected || (!isRecording && !isAudioInputTestRunning))) {
            readAudioInput({ deviceId: inputDeviceId });
        }

        if (error) {
            stopAudioTest();
        }
    }, [error, inputDeviceId, isRecording, isAudioInputTestRunning, readAudioInput, stopAudioTest]);

    useEffect(() => {
        return () => {
            stopAudioTest();
        };
    }, []);

    return (
        <Container>
            <Grid container className={styles['container']} justifyContent="space-between">
                <Grid item md={5} className={styles['center-vertically']}>
                    <h5>Step 3: Test Your Microphone</h5>
                    <ul>
                        <li>
                            Click &apos;Record&apos; and speak an audio clip. Play it back to check
                            that your microphone and speaker are working. If they aren&apos;t, make
                            sure your volume is turned up, and try a different speaker or
                            microphone, and check your bluetooth settings.
                        </li>
                        <li>For best audio quality, please use a headset with a microphone.</li>
                    </ul>
                </Grid>

                <Grid item md={5} className={classes.paperContainer}>
                    <Paper className={styles['paper']}>
                        <div className={classes.topLine}>
                            <Typography variant="subtitle2">
                                <strong>Audio</strong>
                            </Typography>
                            <div>
                                <Button
                                    variant="outlined"
                                    style={{ marginRight: '1em' }}
                                    size="small"
                                    disabled={disableAll}
                                    onClick={handleRecordClick}
                                >
                                    Record
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    disabled={!playbackURI || disableAll}
                                    onClick={handlePlayClick}
                                >
                                    Play back
                                </Button>
                            </div>
                        </div>

                        <AudioDevice
                            disabled={disableAll}
                            kind="audiooutput"
                            onDeviceChange={setOutputDeviceId}
                            setDeviceError={setError}
                        />
                        <AudioDevice
                            disabled={disableAll}
                            kind="audioinput"
                            onDeviceChange={setInputDeviceId}
                            setDeviceError={setError}
                            error={error}
                        />
                        <div className={classes.audioLevelContainer}>
                            <div
                                style={{ width: '2em', display: 'flex', justifyContent: 'center' }}
                            >
                                {isAudioOutputTestRunning ? <Speaker /> : <Microphone />}
                            </div>
                            <ProgressBar
                                position={volumeLevel}
                                duration={0.1}
                                style={{ flex: '1' }}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
