import React, { useEffect, useState  } from 'react';
import useSubject from '../hooks/useSubject';
import roomService from '../services/roomService';
import { providerAuthToken$, room$ } from '../services/subjects';


/** Dependencies providerAuthToken, appointmentDetails */
function withTranslatorLink(ChildComponent) {

    return (props) => {
        const { appointmentDetails, providerAuthToken } = props
        const { id, memberId } = (appointmentDetails || {})
        const [translatorLink, setTranslatorLink] = useState();
        const [translatorLinkError, setTranslatorLinkError] = useState();

        useEffect(() => {
            if (!appointmentDetails || !providerAuthToken) return;
            const getTranslatorLinkSubscription$ = roomService.getTranslatorLink$(id, memberId).subscribe((res) => {
                setTranslatorLink(res.response);
            }, (err) => {
                console.log('error', err);
                setTranslatorLinkError(err)
            });
            return () => getTranslatorLinkSubscription$.unsubscribe();
        }, [appointmentDetails, providerAuthToken])

        return <ChildComponent {...props} translatorLinkError={translatorLinkError} translatorLink={translatorLink} />
    }
}

export default withTranslatorLink;
