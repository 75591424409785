import React from 'react';
import PropTypes from 'prop-types';
import Participant, { ParticipantPropType } from './Participant';

import styles from './Participants.module.scss';

const Participants = ({ participants, hostParticipant, appointment }) => {
    return (
        <div className={styles.participants}>
            {participants.map((p) => (
                <Participant hostParticipant={hostParticipant} participant={p} key={p.sid} appointment={appointment} />
            ))}
        </div>
    );
};

Participants.displayName = Participants;
Participants.propTypes = {
    participants: PropTypes.arrayOf(ParticipantPropType),
};
Participants.defaultProps = {
    participants: [],
};

export default Participants;
