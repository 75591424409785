import { useState, useEffect } from 'react';
import { room$ } from '../services/subjects';
import { internalRoomStatusesMap } from '../common/constants';

const INITIAL_ROOM = null;
const INITIAL_INTERNAL_ROOM_STATUS = internalRoomStatusesMap.NOT_CONNECTED;

export default function useRoom () {
    const [room, setRoom] = useState(INITIAL_ROOM);
    const [roomStatus, setRoomStatus] = useState(INITIAL_INTERNAL_ROOM_STATUS);

    useEffect(() => {
        const subscription = room$.subscribe({
            next: ({room, roomInternalStatus}) => {
                setRoom(room);
                setRoomStatus(roomInternalStatus);
            }
        });
        return () => {
            subscription.unsubscribe();
        }
    }, [])
    return {room, roomStatus, setRoom};
}