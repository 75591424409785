import { ajax } from 'rxjs/ajax';

const conversationService = {
    getToken$: (identity) =>
        ajax({
            url: `api/conversations/accessToken/${identity}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }),
};

export { conversationService };
