import { useState, useEffect } from 'react';

export default function useDevices() {
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        const getDevices = () => {
            navigator.mediaDevices.enumerateDevices().then((newDevices) => setDevices(newDevices));
        };

        navigator.mediaDevices
            .getUserMedia({ audio: true, video: true })
            .then(() => {
                getDevices();
            })
            .catch((error) => {
                console.log(error);
            });

        navigator.mediaDevices.addEventListener('devicechange', getDevices);

        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', getDevices);
        };
    }, []);

    return {
        audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
        videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
        audioOutputDevices: devices.filter((device) => device.kind === 'audiooutput'),
    };
}
