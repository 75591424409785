import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
let appInsights;

function getAppInsights() {
    if (appInsights) {
        return appInsights;
    }

    return {
        trackEvent: function (eventData, customProperties) {},
    };
}

function initAppInsights(instrumentationKey, browserHistory) {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: instrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
        },
    });

    appInsights.loadAppInsights();
    return appInsights;
}

const trackEvent = (eventInfo, customProperties) => {
    const appInsights = getAppInsights();
    appInsights.trackEvent(eventInfo, customProperties);
};

export { getAppInsights, initAppInsights, reactPlugin, trackEvent };
