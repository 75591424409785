import React, { useEffect, useState  } from 'react';
import useSubject from '../hooks/useSubject';
import roomService from '../services/roomService';
import { providerAuthToken$, room$ } from '../services/subjects';


/** Dependencies providerAuthToken, appointmentDetails */
function withFetchOrCreateRoomAndToken(ChildComponent) {

    return (props) => {
        const [roomCreated, setRoomCreated] = useState(null);
        const [roomToken, setRoomToken] = useState('');
        const [roomError, setRoomError] = useState(null)
        const { appointmentDetails } =  props;
        const providerAuthToken = useSubject(providerAuthToken$);
        const { memberId, id } = appointmentDetails || {};
        useEffect(() => {
            if (!appointmentDetails || !providerAuthToken) return;
            const createRoomSubscription = roomService.post$(id, memberId).subscribe((res) => {
                setRoomCreated(res.response);
            }, (err) => {
                console.log('error', err);
                setRoomError(err)
            });
            return () => createRoomSubscription.unsubscribe();
        }, [appointmentDetails, providerAuthToken])

        useEffect(() => {
            if (!roomCreated) return;
            const getTokenSubscription = roomService.getProviderToken$(id, memberId).subscribe((res) => {
                const token = res.response;
                setRoomToken(token);
            }, (err) => {
                console.log('error', err);
                setRoomError(err)
            });
            return () => getTokenSubscription.unsubscribe();
        }, [roomCreated])

        return <ChildComponent {...props} roomCreated={roomCreated} roomToken={roomToken} roomError={roomError} />
    }
}

export default withFetchOrCreateRoomAndToken;
