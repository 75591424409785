import React from 'react';
import PropTypes from 'prop-types';
import ParticipantInfo from './ParticipantInfo';
import LinkInfo from './LinkInfo';

import styles from './RoomInfo.module.scss';

const RoomInfo = ({ participants, appointment, localParticipant, onCopyInvite }) => {
    return (
        <div className={styles['room-info']}>
            <h4>Participants</h4>
            {participants.map((p) => (
                <ParticipantInfo
                    localParticipant={localParticipant}
                    key={p.sid}
                    participant={p}
                    appointment={appointment}
                />
            ))}
            <h4>Appointment links</h4>
            <LinkInfo fullName={appointment.memberFullName} onCopyLink={onCopyInvite} />
            {appointment.optionalAttendees?.map((optionalAttendee) => {
                return (
                    <LinkInfo
                        key={optionalAttendee.connectionId}
                        fullName={optionalAttendee.fullName}
                        participant={optionalAttendee}
                        appointment={appointment}
                        onCopyLink={onCopyInvite}
                    />
                );
            })}
        </div>
    );
};

RoomInfo.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.object),
};
RoomInfo.defaultProps = {
    participants: [],
};

export default RoomInfo;
