import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: require('./locales/en.json') },
        es: { translation: require('./locales/es.json') },
        zh: { translation: require('./locales/zh.json') },
        vi: { translation: require('./locales/vi.json') },
    },
    lng: 'en', // Default language
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
