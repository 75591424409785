import {useState, useEffect, useRef} from 'react';
import { isCommingFromPcpOffice } from '../common/utility';
import useRoom from './useRoom';
import {unknownGuests$} from '../services/subjects';
import useUnknownGuests from '../hooks/useUnknownGuests';

const INITIAL_PARTICIPANTS = []
const INITIAL_HOST_PARTICIPANT = null

export default function useParticipants(appointment, listenForDominantSpeakerChange = true) {
    const [participants, setParticipants] = useState(INITIAL_PARTICIPANTS);
    const [hostParticipant, setHostParticipant] = useState(INITIAL_HOST_PARTICIPANT);
    const hostParticipantRef = useRef(null);
    let {guests, count} = useUnknownGuests();
    const participantsRef = useRef(null);
    hostParticipantRef.current = hostParticipant;
    participantsRef.current = participants;
    const { room } = useRoom();

    function isHostCandidate(participant) {
        return isCommingFromPcpOffice(participant.identity)
    }

    function isUnknownGuest(identity) {
        if (identity === appointment.memberConnectionId) {
            return false
        }
        const attendee = appointment.optionalAttendees.find((optionalAttendee) => {
            if (optionalAttendee.connectionId === identity) {
                return true;
            }
        });
        return !attendee;
    }



    function isHost(participant) {
        return participant.identity === hostParticipantRef.current?.identity;
    }

    function handleParticipantConnected(participant) {
        if(listenForDominantSpeakerChange) {
            if (isHostCandidate(participant) && !hostParticipantRef.current) {
                setHostParticipant(participant);
            } else if(isUnknownGuest(participant.identity)) {
                unknownGuests$.next([{...guests, [participant.identity]: `Guest ${++count}`}, count]);
            }
        }
        setParticipants(participants => [...participants, participant]);
    }

    function handleParticipantDisconnected(participant) {
        if(isHost(participant)) {
            const newHost = participantsRef.current.find(p => isHostCandidate(p) && !isHost(p));
            if(newHost) {
                setHostParticipant(newHost);
            } else {
                setHostParticipant(null);
            }
        } 
        setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
    }

    function handleDominantSpeakerChanged(participant) {
        if(!participant) {
            return;
        }
        if(isHostCandidate(participant)) {
            if(!isHost(participant)) {
                setHostParticipant(participant);
            }
        }
    }

    useEffect(() => {
        if(room) {
            const participants = Array.from(room.participants.values());
            const hostParticipant = participants.find(p => isHostCandidate(p));
            setHostParticipant(hostParticipant);
            setParticipants([room.localParticipant, ...participants]);

            room
            .on('participantConnected', handleParticipantConnected)
            .on('participantDisconnected', handleParticipantDisconnected);
            if (listenForDominantSpeakerChange) {
                room.on('dominantSpeakerChanged', handleDominantSpeakerChanged);
            }

            return () => {
                room
                .off('participantConnected', handleParticipantConnected)
                .off('participantDisconnected', handleParticipantDisconnected);
                if (listenForDominantSpeakerChange) {
                    room.off('dominantSpeakerChanged', handleDominantSpeakerChanged);
                }
            }
        }
    }, [room, appointment])

    return {hostParticipant, participants}
}