import React from 'react';
import BaseComponent from '../components/BaseComponent';
import { map, tap } from 'rxjs/operators';
import roomService from '../services/roomService';
import { appointment$ } from '../services/subjects';
import { participantVisitEvent } from '../common/constants';

export default function withRoomDetails(WrappedComponent) {
    return class extends BaseComponent {
        constructor(props) {
            super(props);
            this.state = {
                loadingRoomDetails: false,
                appointmentDetails: null,
                failureRoomDetails: null,
                participantId: null,
                appointmentId: null,
            };
            this.subscriptions = [];

            roomService.trackEvent$(
                props.router.params.appointmentId,
                props.router.params.participantId,
                participantVisitEvent.joined
            );
        }

        getRoomDetails = (appointmentId, participantId) => {
            this.setState({
                loadingRoomDetails: true,
            });
            const subscription = roomService
                .get$(appointmentId, participantId)
                .pipe(
                    map((response) => response.response),
                    tap((response) => appointment$.next(response.appointment))
                )
                .subscribe(
                    (response) => {
                        this.setState({
                            appointmentDetails: response.appointment,
                            loadingRoomDetails: false,
                        });
                    },
                    (error) =>
                        this.setState({ failureRoomDetails: error, loadingRoomDetails: false })
                );
            this.subscriptions.push(subscription);
        };

        componentDidMount() {
            const appointmentId = this.props.router.params.appointmentId;
            const participantId = this.props.router.params.participantId;
            this.setState({ participantId, appointmentId });
            this.getRoomDetails(appointmentId, participantId);
        }

        componentDidUpdate(prevProps) {
            const prevId = prevProps.router.params.appointmentId;
            const { appointmentId } = this.props.router.params;
            if (prevId !== appointmentId && appointmentId) {
                this.setState({ appointmentId });
                this.getRoomDetails(appointmentId);
            }
        }

        render() {
            return <WrappedComponent {...this.state} {...this.props} />;
        }
    };
}
