import React, { useEffect, useState, useRef } from 'react';
import useEvents from '../../hooks/useEvents';
import useRoom from '../../hooks/useRoom';
import useTrackEnabled from '../../hooks/useTrackEnabled';
import { room$, conversationSid$, conversation$ } from '../../services/subjects';
import { internalRoomStatusesMap } from '../../common/constants';

import styles from './Tracks.module.scss';

const Tracks = ({ tracks, participant }) => {
    const tracksRef = useRef(null);
    const isVideoEnabled = useTrackEnabled(tracks);
    const { subscribe, lastEvent } = useEvents();
    const { room } = useRoom();
    const [subscriptions, setSubscriptions] = useState([]);
    const [conversation, setConversation] = useState(null);

    useEffect(() => {
        if (lastEvent && room) {
            if (participant !== room?.localParticipant) {
                return;
            }
            switch (lastEvent.eventKind) {
                case 'mute': {
                    const { params } = lastEvent;
                    if (participant?.identity === params.identity) {
                        tracks.forEach((track) => {
                            if (track?.kind === 'audio') {
                                if (track.isEnabled) {
                                    track.disable();
                                } else {
                                    track.enable();
                                }
                            }
                        });
                    }
                    break;
                }
                case 'logout': {
                    const { params } = lastEvent;
                    if (participant?.identity === params.identity) {
                        room.disconnect();
                        room$.next({
                            room: null,
                            roomInternalStatus: internalRoomStatusesMap.DISCONNECTED_BY_HOST,
                        });
                        if (conversation) {
                            conversation.leave();
                            conversation$.next(null);
                        }
                    }
                    break;
                }
                case 'conversation': {
                    conversationSid$.next(lastEvent.params.conversationSid);
                    break;
                }
                default: {
                    throw new Error('Not implemented');
                }
            }
        }
    }, [lastEvent, room]);

    useEffect(() => {
        const subscription = subscribe();
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const el = tracksRef.current;
        const oldTracks = tracks.slice();
        oldTracks.forEach((t) => {
            if (t.kind !== 'data') {
                el.appendChild(t.attach());
            }
        });
        return () => {
            if (el) {
                el.innerHTML = '';
            }
        };
    }, [tracks]);

    useEffect(() => {
        const conversationSub = conversation$.subscribe({
            next: (conversation) => {
                setConversation(conversation);
            },
        });
        setSubscriptions([...subscriptions, conversationSub]);

        return () => {
            subscriptions.forEach((sub) => sub.unsubscribe());
        };
    }, []);

    return (
        <div
            className={`${styles.tracks} ${!isVideoEnabled ? styles.disabled : ''}`}
            ref={tracksRef}
        ></div>
    );
};

Tracks.displayName = Tracks.name;

export default Tracks;
