import { weekDays, months } from './constants';
import moment from 'moment';

const MEETING_DATE_FORMAT = 'dddd, MMM DD h:mm a';

export function search(arr, term) {
    if (!arr || !term) return arr;
    return arr.filter(function (obj) {
        return Object.keys(obj).some(function (key) {
            return (
                obj[key] &&
                typeof obj[key] === 'string' &&
                obj[key].toLowerCase().includes(term.toLowerCase())
            );
        });
    });
}

function getInitials(name = '') {
    let invert = false;
    if (/,/.test(name)) {
        invert = true;
    }
    const names = name
        .trim()
        .split(' ')
        .map((name) => name[0]);
    return (invert ? names.reverse() : names).slice(0, 2).join('');
}

export function isCommingFromPcpOffice(identity) {
    return /(pcp office)|(virtual provider)/i.test(identity || '');
}

export function getParticipantInitials(identity, appointment = {}, unknownGuests = {}) {
    const {
        providerConnectionId,
        provider,
        memberConnectionId,
        memberFullName,
        optionalAttendees,
    } = appointment;
    let attendee = null;
    if (identity === providerConnectionId) {
        return getInitials(provider.providerFullName);
    } else if (identity === memberConnectionId) {
        return getInitials(memberFullName);
    } else if ((attendee = optionalAttendees.find((oa) => oa.connectionId === identity))) {
        return getInitials(attendee.fullName);
    } else {
        if (isCommingFromPcpOffice(identity)) {
            return getInitials(identity.split(/\s?-\s?/)[1]);
        } else {
            return getInitials(unknownGuests[identity] || '?');
        }
    }
}

export function isAudioAppointment(appointment) {
    return appointment.type === 'Phone' || appointment.type === 1;
}

export function openPdfResponseInNewTab(response) {
    const blob = new Blob([response], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export function navigateToBlobLink(blob) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export function downloadImage(response) {
    const blob = new Blob([response], { type: 'image/jpeg' });
    navigateToBlobLink(blob);
}

export function downloadFile(response, contentType) {
    const blob = new Blob([response], { type: contentType });
    navigateToBlobLink(blob);
}

export function downloadImages(response) {
    console.log(response);
    const blob = new Blob(response, { type: 'image/jpeg' });
    console.log(blob);
    navigateToBlobLink(blob);
}

export function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) => (binary += String.fromCharCode(b)));

    return window.btoa(binary);
}

export function getComparerFn(field, direction) {
    return (a, b) => (a[field] < b[field] ? -1 : 1) * direction;
}

export function yyyyddmmTommddyyyy(date) {
    if (!date) {
        return 'N/A';
    }
    var parts = date.split('-');
    return parts.length === 3 ? `${parts[1]}/${parts[2]}/${parts[0]}` : 'N/A';
}

export function dateToStr(date) {
    if (!date || !date.getMonth) {
        return 'N/A';
    }
    let year = date.getFullYear(),
        month = date.getMonth() + 1,
        day = date.getDate();
    return `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
}

export function dateToEnglishStr(date) {
    if (!date || !date.getMonth) {
        return 'N/A';
    }
    return `${weekDays[date.getDay()]}, ${
        months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
}

export function getTimeStr(time) {
    if (time.hour < 12) {
        return `${time.hour}:${time.minute < 10 ? '0' + time.minute : time.minute} AM`;
    } else {
        return `${time.hour === 12 ? time.hour : time.hour - 12}:${
            time.minute < 10 ? '0' + time.minute : time.minute
        } PM`;
    }
}

export function fromDateTimeToMeetingTime(date, time, format = MEETING_DATE_FORMAT) {
    const startTime = moment(date)
        .set({
            hour: time.hour,
            minute: time.minute,
            second: 0,
        })
        .format(format);

    return {
        startTime,
        endTime: moment(startTime).add(time.duration, 'minutes').format(format),
    };
}

export function getMeetingTime(date, format = MEETING_DATE_FORMAT) {
    return moment(date).format(format);
}

export function isNumericInput({ keyCode }) {
    return (
        (keyCode >= 48 && keyCode <= 57) || // allow number line
        (keyCode >= 96 && keyCode <= 105) // allow number pad
    );
}

export function isModifierKey(event) {
    const { keyCode, shiftKey, ctrlKey, metaKey } = event;
    return (
        shiftKey === true ||
        keyCode === 35 ||
        keyCode === 36 || // allow shift, home and end
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 46 || // allow backspace, tab, enter and delete
        (keyCode > 36 && keyCode < 41) || // Allow left, up, right, down
        // allow ctrl/command + a, c, v, x, and z
        ((ctrlKey === true || metaKey === true) &&
            (keyCode === 65 ||
                keyCode === 67 ||
                keyCode === 86 ||
                keyCode === 88 ||
                keyCode === 90))
    );
}

export function enforcePhoneFormat(event) {
    // input must be of a valid number format or a modifier key
    if (!isNumericInput(event) && !isModifierKey(event)) {
        event.preventDefault();
    }
}

export function formatToPhone(event) {
    if (isModifierKey(event)) {
        return;
    }
    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // first ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6);

    if (input.length > 6) {
        event.target.value = `(${zip}) ${middle} - ${last}`;
    } else if (input.length > 3) {
        event.target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
        event.target.value = `(${zip}`;
    }
}

export function isMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export function getLangCode(language) {
    switch (language) {
        case 'SPANISH':
            return 'es';
        case 'CHINESE':
            return 'zh';
        case 'VIETNAMESE':
            return 'vi';
        default:
            return 'en';
    }
}

/**
 * Get Operating System from the client device.
 */
export function getDeviceOS() {
    const userAgent = navigator.userAgent;
    let operatingSystem = 'Unknown';

    if (userAgent.match(/Android/i)) {
        operatingSystem = 'Mobile - Android';
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
        operatingSystem = 'Mobile - iOS';
    } else if (userAgent.match(/Mac/i)) {
        operatingSystem = 'Desktop - macOS';
    } else if (userAgent.match(/Windows/i)) {
        operatingSystem = 'Desktop - Windows';
    } else if (userAgent.match(/Linux/i)) {
        operatingSystem = 'Desktop - Linux';
    }

    return operatingSystem;
}

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const currencyFormatterNearestDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export { currencyFormatter, currencyFormatterNearestDollar };
