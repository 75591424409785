import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ajax } from 'rxjs/ajax';
import { initAppInsights } from './ApplicationInsights';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import './i18n';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>,
    rootElement
);

unregisterServiceWorker();

ajax('api/config').subscribe({
    next: (response) => {
        initAppInsights(response.response.instrumentationKey);
    },
});
