import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Toaster.module.scss';
import { copyText } from '../../common/constants';
import Toast from 'react-bootstrap/Toast';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

function Toaster({ show, displayText, onCloseAction }) {
    return (
        <Toast
            animation={true}
            className={styles['toaster-alert']}
            show={show}
            delay={2000}
            autohide
            onClose={onCloseAction}
        >
            <Toast.Body className={styles['toaster-alert-body']}>
                <FontAwesomeIcon icon={faThumbsUp} /> {displayText}
            </Toast.Body>
        </Toast>
    );
}

Toaster.defaultProps = {
    show: false,
    displayText: copyText,
};

export default Toaster;
