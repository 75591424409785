import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import styles from './LoadingScreen.module.scss';

function LoadingScreen() {
    return (
        <div className={styles['loading-container']}>
            <FontAwesomeIcon
                className={styles.spinner}
                icon={faSpinner}
            />
        </div>
    )
}

export default LoadingScreen;
