import React from 'react';
import useTracks from '../../hooks/useTracks';
import useTrackEnabled from '../../hooks/useTrackEnabled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { isAudioAppointment } from '../../common/utility';
import useUnkownGuests from '../../hooks/useUnknownGuests';

import styles from './ParticipantInfo.module.scss';


function ParticipantInfo({ participant, appointment }) {
    const {guests} = useUnkownGuests();
    const { tracks } = useTracks(participant, false, isAudioAppointment(appointment));
    const isAudioTrackEnabled = useTrackEnabled(tracks, 'audio');

    function isEnabled() {
        return isAudioTrackEnabled;
    }

    function getParticipantIdentity(identity) {
        if (identity === appointment.memberConnectionId) {
            return appointment.memberFullName;
        }

        if (identity === appointment.providerConnectionId) {
            return appointment.provider.providerFullName;
        }

        const attendee = appointment.optionalAttendees.find((oa) => {
            if (oa.connectionId === identity) {
                return true;
            }
        });
        if(attendee) {
            return attendee?.fullName 
        }

        if(/(pcp)|(virtual provider)/i.test(identity)) {
            return identity
        }

        return guests[identity] ||'Unknown Guest';
    }
    
    return (
        <div className={styles.participant}>
            {getParticipantIdentity(participant.identity)}
            <FontAwesomeIcon
                className={`${styles.icon} ${isEnabled() ? styles.enabled : styles.disabled}`}
                icon={faMicrophone}
            />
        </div>
    );
}

export default ParticipantInfo;
