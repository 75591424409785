import React from 'react';
import HostTrack from './HostTrack';
import useTracks from '../../hooks/useTracks';
import styles from './HostParticipant.module.scss';
import {getParticipantInitials, isAudioAppointment} from '../../common/utility';

function HostParticipant({ participant, appointment }) {
    const { tracks } = useTracks(participant, false, isAudioAppointment(appointment));

    return (
        <div className={styles['host-participant']}>
            <div className={styles.identity}><span className={styles['identity-initials']}>{getParticipantInitials(participant?.identity, appointment)}</span></div>
            <HostTrack tracks={tracks} participant={participant}  appointment={appointment} />
        </div>
    );
}

export default HostParticipant;
