export const categories = [
    'Authorization or Referral',
    'Benefits',
    'Billing or Claims',
    'Rewards',
    'Schedule Service or Appointment',
    'Give a Compliment',
    'Other',
];

export const plansWithPremiums = [
    'H3815-009-2020',
    'H3815-018-2020',
    'H3815-025-2020',
    'H3815-019-2020',
    'H3815-020-2020',
    'H3815-021-2020',
    'H3815-022-2020',
    'H3815-023-2020',
    'H3815-024-2020',
    'H4961-001-2020',
    'H4961-002-2020',
    'H4961-003-2020',
    'H4961-004-2020',
];

export const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const appointmentStatuses = {
    Invalid: 'Invalid',
    NotStarted: 'NotStarted',
    Started: 'Started',
    Completed: 'Completed',
    CanceledByMember: 'CanceledByMember',
    Rescheduled: 'Rescheduled',
    NoShow: 'NoShow',
    CanceledByProvider: 'CanceledByProvider',
    Confirmed: 'Confirmed',
    MissedAppointment: 'MissedAppointment',
};

//match VirtualAppointmentStatus.cs
export const appointmentStatusMap = {
    0: 'Invalid',
    1: 'NotStarted',
    2: 'Started',
    3: 'Completed',
    4: 'CanceledByMember',
    5: 'Rescheduled',
    6: 'NoShow',
    7: 'CanceledByProvider',
    8: 'Confirmed',
    9: 'MissedAppointment',
};

export const internalRoomStatusesMap = {
    NOT_CONNECTED: -1,
    CONNECTED: 0,
    DISCONNECTED_BY_HOST: 1,
    CALL_ENDED: 2,
};

export const participantVisitEvent = {
    invalid: 0,
    enteredWaitingRoom: 1,
    disconnected: 2,
    joined: 3,
    deviceAccessDenied: 4,
};

export const copyText = 'text copied!';
