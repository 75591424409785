import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useTracks from '../../hooks/useTracks';
import Tracks from '../ProviderVirtualRoom/Tracks';
import { getParticipantInitials, isAudioAppointment } from '../../common/utility';
import useUnknownGuests from '../../hooks/useUnknownGuests';
import { trackEvent } from '../../ApplicationInsights';

import styles from './Participant.module.scss';

const Participant = ({ participant, appointment, hostParticipant }) => {
    const { guests } = useUnknownGuests();
    const { tracks } = useTracks(participant, true, isAudioAppointment(appointment));

    useEffect(() => {
        participant.on('networkQualityLevelChanged', logNetworkQualityStats);
    }, []);

    const logNetworkQualityStats = (networkQualityLevel, networkQualityStats) => {
        const event = { name: 'Telehealth Network Quality' };
        const networkData = {
            appointmentId: appointment.id,
            memberConnectionId: appointment.memberConnectionId,
            identity: null,
            networkQualityLevel: networkQualityLevel,
            networkQualityStats: networkQualityStats,
        };

        if (participant.identity === appointment.memberConnectionId) {
            networkData.identity = 'Local';
        } else {
            networkData.identity = 'Remote';
        }

        trackEvent(event, networkData);
    };

    return (
        <div
            className={`${styles.participant} ${
                hostParticipant === participant ? styles.hidden : ''
            }`}
        >
            <div className={styles.identity}>
                <span className={styles['identity-initials']}>
                    {getParticipantInitials(participant?.identity, appointment, guests)}
                </span>
            </div>
            <Tracks tracks={tracks} participant={participant} />
        </div>
    );
};

Participant.displayName = Participant.name;
const ParticipantPropType = PropTypes.shape({
    sid: PropTypes.string.isRequired,
    identity: PropTypes.string.isRequired,
});
Participant.propTypes = {
    participant: ParticipantPropType,
};

export { ParticipantPropType };
export default Participant;
