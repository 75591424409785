import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

import styles from './LinkInfo.module.scss';

function LinkInfo({ participant, fullName, onCopyLink }) {
    return (
        <div className={styles.participant}>
            {fullName}{' '}
            {
                <FontAwesomeIcon
                    onClick={onCopyLink.bind(this, participant)}
                    className={styles.icon}
                    icon={faClipboard}
                />
            }
        </div>
    );
}

LinkInfo.defaultProps = {
    onCopyLink: () => {},
};

export default LinkInfo;
