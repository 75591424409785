import { Subject, BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { internalRoomStatusesMap, participantVisitEvent } from '../common/constants';
import { filter, takeUntil, delay, take, switchMap, map, tap } from 'rxjs/operators';
import roomService from './roomService';

const appointmentId$ = new ReplaySubject();

const participantId$ = new ReplaySubject();

const room$ = new BehaviorSubject({
    room: null,
    roomInternalStatus: internalRoomStatusesMap.NOT_CONNECTED,
});

const token$ = new BehaviorSubject(null);

const providerAuthToken$ = new BehaviorSubject('');

const appointment$ = new ReplaySubject(1);

const roomCreated$ = new BehaviorSubject();

const signalRError$ = new BehaviorSubject();

const unknownGuests$ = new BehaviorSubject([{}, 0]);

const conversationSid$ = new BehaviorSubject(null);

const conversation$ = new BehaviorSubject(null);

const events$ = new Subject(null);

const retryTimes = [0, 3000, 10000, 60000];
var connection = new HubConnectionBuilder()
    .withUrl('/roomHub')
    .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (context) => {
            const index =
                context.previousRetryCount < retryTimes.length
                    ? context.previousRetryCount
                    : retryTimes.length - 1;
            return retryTimes[index];
        },
    })
    .build();

connection.on('RoomCreated', function () {
    roomCreated$.next(null, true);
});

connection
    .start()
    .then(function () {
        connection.invoke('SubscribeToRoom', window.location.pathname.split('/')[1]);
    })
    .catch(function (err) {
        signalRError$.next(err);
    });

combineLatest(appointmentId$, participantId$, room$)
    .pipe(
        filter(
            ([appointmentId, participantId, { roomInternalStatus }]) =>
                roomInternalStatus === internalRoomStatusesMap.CALL_ENDED ||
                roomInternalStatus === internalRoomStatusesMap.DISCONNECTED_BY_HOST
        )
    )
    .subscribe({
        next: ([appointmentId, participantId, _]) =>
            roomService
                .trackEvent$(appointmentId, participantId, participantVisitEvent.disconnected)
                .subscribe(),
    });

combineLatest(appointmentId$, participantId$, room$)
    .pipe(
        filter(
            ([appointmentId, participantId, { roomInternalStatus }]) =>
                roomInternalStatus === internalRoomStatusesMap.CONNECTED
        ),
        tap((_) => participantJoined.next())
    )
    .subscribe({
        next: ([appointmentId, participantId, _]) =>
            roomService
                .trackEvent$(appointmentId, participantId, participantVisitEvent.joined)
                .subscribe(),
    });

combineLatest(appointmentId$, participantId$, room$)
    .pipe(
        filter(
            ([appointmentId, participantId, { roomInternalStatus }]) =>
                roomInternalStatus === internalRoomStatusesMap.NOT_CONNECTED
        ),
        tap((_) => participantEnteredWaitingRoom.next())
    )
    .subscribe({
        next: ([appointmentId, participantId, _]) =>
            roomService
                .trackEvent$(appointmentId, participantId, participantVisitEvent.enteredwaitingroom)
                .subscribe(),
    });

const waitingTimeLimitInSeconds = 5;
const participantEnteredWaitingRoom = new Subject();
const participantJoined = new Subject();
const memberWaitingTooLong$ = combineLatest(appointment$, participantId$).pipe(
    filter(([appointment, participantId]) => appointment.memberConnectionId === participantId),
    map(([appointment, participantId]) => appointment.id),
    delay(waitingTimeLimitInSeconds * 1000),
    takeUntil(participantJoined),
    take(1)
);

memberWaitingTooLong$
    .pipe(switchMap((appointmentId) => roomService.notifyProvider$(appointmentId)))
    .subscribe();

export {
    room$,
    roomCreated$,
    appointment$,
    token$,
    events$,
    signalRError$,
    unknownGuests$,
    appointmentId$,
    participantId$,
    providerAuthToken$,
    conversationSid$,
    conversation$,
};
