import { useState, useEffect } from 'react';

function useSubject(subject) {
    const [nextValue, setNextValue] = useState(null);

    useEffect(() => {
        const subscription = subject.subscribe(setNextValue);
        return () => {
            subscription.unsubscribe();
        }
    }, [subject]);

    return nextValue
}

export default useSubject;