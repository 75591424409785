import React, { useState, useEffect } from 'react';
import { Button, FormControl } from 'react-bootstrap';

// I just made this simple admin page for creating and deleting
// Twilio Conversations (can't do it through their web console)
const Admin = (props) => {
    const [conversations, setConversations] = useState([]);
    const [conversationFriendlyName, setConversationFriendlyName] = useState(null);

    useEffect(() => {
        getConversations();
    }, []);

    const getConversations = () => {
        fetch('api/conversations', {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                setConversations(data);
            });
    };

    const createNewConversation = () => {
        fetch(`api/conversations/create/${conversationFriendlyName}`, {
            method: 'POST',
            header: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (response.ok) {
                getConversations();
                setConversationFriendlyName(null);
            }
        });
    };

    const deleteConversation = (sid) => {
        fetch(`api/conversations/delete/${sid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (response.ok) {
                getConversations();
            }
        });
    };

    const onConversationFriendlyNameChange = (args) => {
        setConversationFriendlyName(args.target.value);
    };

    return (
        <div>
            <p>New Conversation</p>
            <FormControl
                type="text"
                placeholder="Friendly Name"
                value={conversationFriendlyName}
                onChange={onConversationFriendlyNameChange}
            />
            <Button onClick={createNewConversation}>Create New Conversation</Button>
            <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                {conversations.map((conversation, idx) => (
                    <div key={`conversation_${idx}`} style={{ marginTop: '10px' }}>
                        {conversation.friendlyName} ({conversation.sid}){' '}
                        <Button
                            onClick={() => {
                                deleteConversation(conversation.sid);
                            }}
                            variant="danger"
                        >
                            Delete
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Admin;
