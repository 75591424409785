import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faVideo, faPhone, faClipboard } from '@fortawesome/free-solid-svg-icons';
import useTracks from '../../hooks/useTracks';
import useTrackEnabled from '../../hooks/useTrackEnabled';
import { isAudioAppointment } from '../../common/utility';
import styles from './RoomActions.module.scss';
const DEFAULT_TIMEOUT = 2000;
function RoomActions({
    appointment,
    onChangeMicrophone,
    onChangeCamera,
    onLeave,
    onCopy,
    participant,
    translatorLink,
    doShowCopyAction,
    copyText,
}) {
    const { tracks: participantTracks } = useTracks(
        participant,
        false,
        isAudioAppointment(appointment)
    );
    const isAudioTrackEnabled = useTrackEnabled(participantTracks, 'audio');
    const isVideoEnabled = useTrackEnabled(participantTracks, 'video');

    return (
        <div className={styles.actions}>
            <div
                className={`${isAudioTrackEnabled ? '' : styles.inactive} ${styles.action}`}
                onClick={onChangeMicrophone}
            >
                <FontAwesomeIcon icon={faMicrophone} />
            </div>
            {(!isAudioAppointment(appointment) || null) && (
                <div
                    className={`${isVideoEnabled ? '' : styles.inactive} ${styles.action}`}
                    onClick={onChangeCamera}
                >
                    <FontAwesomeIcon icon={faVideo} />
                </div>
            )}
            <div className={`${styles.action} ${styles.inactive}`} onClick={onLeave}>
                <FontAwesomeIcon icon={faPhone} />
            </div>

            {translatorLink && (
                <button
                    type="button"
                    onClick={onCopy}
                    className={`${styles.action} ${styles.clip}`}
                >
                    <FontAwesomeIcon icon={faClipboard} />
                </button>
            )}
        </div>
    );
}

RoomActions.defaultProps = {
    copyText: 'text copied!',
};

export default RoomActions;
