import React, { useState, useEffect, useRef } from 'react';
import { testVideoInputDevice, VideoInputTest } from '@twilio/rtc-diagnostics';
import {
    Button,
    Container,
    FormControl,
    makeStyles,
    Grid,
    MenuItem,
    Paper,
    Select,
    Typography,
} from '@material-ui/core';
import { SmallError } from '../utils/icons/SmallError';
import useDevices from '../../../hooks/useDevices';
import styles from '../TelehealthTest.module.scss';

const useStyles = makeStyles({
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    videoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    timer: {
        display: 'inline-block',
        position: 'absolute',
        width: '50px',
        height: '50px',
        right: '15px',
        bottom: '15px',
        fontSize: '2rem',
        textAlign: 'center',
        color: 'white',
        background: '#153a5e',
        borderRadius: '25px',
    },
    aspectRatioContainer: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        padding: '1em',
        margin: '1em 0',
        background: 'black',
        '&::after': {
            content: '""',
            paddingTop: '56.25%',
        },
        '& video': {
            position: 'absolute',
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        margin: '0.5em 0',
        '& svg': {
            marginRight: '0.3em',
        },
    },
});

const durationOfVideoTest = 10000;
const timerMilliSeconds = 1000;
const durationInSeconds = durationOfVideoTest / timerMilliSeconds;

export function CameraTest() {
    const classes = useStyles();

    const { videoInputDevices } = useDevices();
    const [videoInputDeviceID, setVideoInputDeviceID] = useState('');

    const setDevice = (deviceID) => {
        setVideoInputDeviceID(deviceID);
    };

    const [isTestInProgress, setTestInProgress] = useState(false);
    const [videoTestError, setVideoTestError] = useState(false);
    const [secondsCounter, setSecondsCounter] = useState(durationInSeconds);
    const videoElementRef = useRef();
    const videoTestRef = useRef();
    const videoTestTimer = useRef();
    const timeIntervalRef = useRef();

    useEffect(() => {
        const hasSelectedDevice = videoInputDevices.some(
            (device) => device.deviceId === videoInputDeviceID
        );
        if (videoInputDevices.length && !hasSelectedDevice) {
            setVideoInputDeviceID(videoInputDevices[0].deviceId);
        }
    }, [videoInputDevices, videoInputDeviceID]);

    const videoTestHandler = () => {
        setSecondsCounter(durationInSeconds);
        if (!isTestInProgress) {
            videoTestRef.current = testVideoInputDevice({
                element: videoElementRef.current,
            });

            videoTestRef.current.on(VideoInputTest.Events.Error, (error) => {
                setVideoTestError(error);
            });

            setTestInProgress(true);

            videoTestTimer.current = setTimeout(() => {
                videoTestRef.current.stop();
                clearTimeout(timeIntervalRef.current);
                setTestInProgress(false);
                setSecondsCounter(durationInSeconds);
            }, durationOfVideoTest);

            timeIntervalRef.current = setInterval(() => {
                setSecondsCounter((prev) => (prev -= 1));
            }, timerMilliSeconds);
        } else {
            videoTestRef.current.stop();
            videoTestRef.current = null;
            clearTimeout(videoTestTimer.current);
            clearTimeout(timeIntervalRef.current);
            setTestInProgress(false);
            setSecondsCounter(durationInSeconds);
        }
    };

    return (
        <Container>
            <Grid container className={styles['container']} justifyContent="space-between">
                <Grid item md={5} className={styles['center-vertically']}>
                    <h5>Step 2: Test Your Camera</h5>
                    <ul>
                        <li>Make sure you have a strong network connection.</li>
                        <li>
                            Move in front of your camera to make sure you see yourself in the video.
                            If you don&apos;t see yourself in the video test, try changing the
                            selected camera.
                        </li>
                        <li>
                            If the camera isn&apos;t part of your computer, check your settings to
                            make sure your system recognizes it.
                        </li>
                    </ul>
                </Grid>

                <Grid item md={5} className={classes.paperContainer}>
                    <Paper className={styles['paper']}>
                        <Grid container direction="column" alignItems="center">
                            <Typography variant="subtitle2">
                                <strong>Video Preview</strong>
                            </Typography>
                            <div className={classes.aspectRatioContainer}>
                                <video ref={videoElementRef} />
                                {secondsCounter !== durationInSeconds ? (
                                    <span className={classes.timer}>{secondsCounter}</span>
                                ) : null}
                            </div>
                        </Grid>
                        <FormControl variant="outlined" fullWidth>
                            <Typography variant="subtitle2">
                                <strong>Camera</strong>
                            </Typography>
                            <Select
                                onChange={(e) => setDevice(e.target.value)}
                                value={videoInputDeviceID}
                                disabled={!!videoTestError}
                            >
                                {videoInputDevices.map((device) => (
                                    <MenuItem value={device.deviceId} key={device.deviceId}>
                                        {device.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {videoTestError && (
                            <div className={classes.error}>
                                <SmallError />
                                <Typography variant="subtitle2" color="error">
                                    Unable to connect.
                                </Typography>
                            </div>
                        )}
                    </Paper>
                    <Button
                        className={styles['start-video-button']}
                        variant="contained"
                        color="primary"
                        onClick={videoTestHandler}
                    >
                        {!isTestInProgress ? `Start Testing Your Camera` : `Stop test`}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}
