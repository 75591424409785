import React from 'react';

const Feature = (props) => {
    const isActive = () => {
        const index = props.featureFlags.findIndex((x) => x.name === props.name);
        if (index < 0) {
            return true;
        }
        return props.featureFlags[index].isActive;
    };

    const getComponentToRender = () => {
        if (isActive()) {
            return props.children;
        }
        return null;
    };

    return <div>{props.featureFlags !== null && getComponentToRender()}</div>;
};

export default Feature;
