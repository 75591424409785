import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useTracks from '../../hooks/useTracks';
import Tracks from './Tracks';
import { getParticipantInitials, isAudioAppointment } from '../../common/utility';
import useUnknownGuests from '../../hooks/useUnknownGuests';
import { trackEvent } from '../../ApplicationInsights';

import styles from './Participant.module.scss';

const Participant = ({ participant, appointment }) => {
    const { guests } = useUnknownGuests();
    const { tracks } = useTracks(participant, true, isAudioAppointment(appointment));
    return (
        <div className={styles['local-participant']}>
            <div className={styles.identity}>
                <span className={styles['identity-initials']}>
                    {getParticipantInitials(participant?.identity, appointment, guests)}
                </span>
            </div>
            <Tracks tracks={tracks} participant={participant} />
        </div>
    );
};

const ParticipantPropType = PropTypes.shape({
    sid: PropTypes.string.isRequired,
    identity: PropTypes.string.isRequired,
});
Participant.propTypes = {
    participant: ParticipantPropType,
};

export { ParticipantPropType };
export default Participant;
