import React from 'react';
import BaseComponent from '../components/BaseComponent';
import { interval } from 'rxjs';
import { map, takeUntil, switchMap, tap, filter, takeWhile } from 'rxjs/operators';
import roomService from '../services/roomService';
import { roomCreated$, token$, signalRError$ } from '../services/subjects';

const DEFAULT_POLLING_INTERVAL_IN_MS = 10000;

export default function withRoomToken(WrappedComponent) {
    return class extends BaseComponent {
        constructor(props) {
            super(props);
            this.state = {
                loadingRoomToken: false,
                token: null,
                failureRoomToken: null
            };
        }
        startTokenFlow = (appointmentId, participantId) => {
            this.setState({loadingRoomToken: true});
            const fetchToken$ = roomService
                .getToken$(appointmentId, participantId)
                .pipe(
                    map(response => response.response.token),
                    tap(token => token$.next(token)),
                );

            const subscription = token$
                .subscribe(
                    token => this.setState({ token, loadingRoomToken: false }),
                    err => this.setState({failureRoomToken: err, loadingRoomToken: false})
                );
            
            const intervalSubscription = signalRError$.pipe(
                takeWhile(err => err),
                switchMap(() => interval(DEFAULT_POLLING_INTERVAL_IN_MS)),
                takeUntil(token$.pipe(filter(token => token))),
                switchMap(() => fetchToken$)
            ).subscribe()

            const signalSubscription = roomCreated$
                .pipe(
                    takeUntil(token$.pipe(filter(token => token))),
                    switchMap(() => fetchToken$),
                )
                .subscribe();
            
            this.subscriptions.push(fetchToken$.subscribe());
            this.subscriptions.push(subscription, signalSubscription, intervalSubscription);
        };

        componentDidMount() {
            const { participantId, appointmentDetails } = this.props;
            if (participantId && appointmentDetails) {
                this.startTokenFlow(appointmentDetails.id, participantId);
            }
        }

        componentDidUpdate(prevProps) {
            const { appointmentDetails: prevAppointmentDetails } = prevProps;
            const { participantId, appointmentDetails } = this.props;
            if (
                appointmentDetails !== prevAppointmentDetails &&
                appointmentDetails &&
                participantId
            ) {
                this.startTokenFlow(appointmentDetails.id, participantId);
            }
        }

        render() {
            return <WrappedComponent {...this.state} {...this.props} />;
        }
    };
}
