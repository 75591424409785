import React, { useState } from 'react';
import { Container, Grid, Paper } from '@material-ui/core';
import { CameraTest } from './CameraTest/CameraTest';
import { AudioTest } from './AudioTest/AudioTest';
import PermissionsScreenshot from './../../assets/img/permissionsScreenshot.png';
import AllowButtonScreenshot from './../../assets/img/allowButtonScreenshot.jpg';
import getVirtualCareScreenshot from './../../assets/img/getVirtualCareScreenshot.jpg';
import joinedVVScreenshot from './../../assets/img/joinedVVScreenshot.jpg';
import yourDoctorIsInScreenshot from './../../assets/img/yourDoctorIsInScreenshot.jpg';
import cameraIconScreenshot from './../../assets/img/cameraIconScreenshot.png';
import androidScreenshot from './../../assets/img/androidScreenshot.jpg';
import iosScreenshot from './../../assets/img/iosScreenshot.jpg';

import styles from './TelehealthTest.module.scss';

const currentYear = new Date().getFullYear();

const TelehealthTest = (props) => {
    const [contentSection, setContentSection] = useState(null);

    const changeContent = (selectedSection) => {
        setContentSection(() => {
            return selectedSection;
        });
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div className={styles['VCT-test']}>
                <div className={styles['header-top-bar']}>
                    <div className={styles['header-blue-bar']} />
                </div>
                {!contentSection && (
                    <>
                        <div className={styles['VCT-description-container']}>
                            <section className={styles['VCT-test-left-text']}>
                                <h1>Virtual Visit Tech Check</h1>
                            </section>
                        </div>
                        <br />
                        <br />
                        <div className={styles['VCT-tests-container']}>
                            <div className={styles['VCT-tests']}>
                                <Container>
                                    <Grid
                                        container
                                        className={styles['container']}
                                        justifyContent="space-between"
                                    >
                                        <Grid item md={5} className={styles['center-vertically']}>
                                            <h5>Step 1: Allow Browser Permissions</h5>
                                            <p>
                                                Before you join a virtual visit, you might see a{' '}
                                                <strong>browser pop-up</strong> appears on{' '}
                                                <strong>the upper left side of your page</strong>{' '}
                                                requesting access to your camera and microphone.
                                                Please click{' '}
                                                <img
                                                    src={AllowButtonScreenshot}
                                                    alt="AllowButtonScreenshot"
                                                    style={{ marginRight: '5px', height: '25px' }}
                                                />
                                                if you see the pop-up on this page, so that your
                                                doctor can see and hear you during the virtual
                                                visit.
                                            </p>
                                            <p>
                                                If you accidentally click &quot;Block&quot; or close
                                                the browser pop-up, you will need to turn on the
                                                permissions from your browser settings manually.
                                            </p>
                                        </Grid>

                                        <Grid item md={5} className={styles['paperContainer']}>
                                            <Paper
                                                className={styles['paper']}
                                                style={{ textAlign: 'center' }}
                                            >
                                                <img
                                                    src={PermissionsScreenshot}
                                                    className={styles['screenshot']}
                                                    alt="PermissionsScreenshot"
                                                />
                                                <p>This is what the browser pop-up looks like</p>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Container>
                                <br />
                                <br />
                                <CameraTest />
                                <AudioTest />
                                <Container>
                                    <Grid
                                        container
                                        className={styles['container']}
                                        justifyContent="space-between"
                                    >
                                        <Grid item md={5}>
                                            <h5>Do you need further assistance?</h5>
                                            <p>
                                                Please call the dedicated toll-free number
                                                available 24 hours/7 days a week to get help.
                                            </p>
                                            <ul>
                                                <li>
                                                    All Alignment Health Plan members
                                                    <br />
                                                    <strong>
                                                        1-833-AHC-ACCESS (833-242-2223) TTY 711,
                                                    </strong>
                                                </li>
                                            </ul>
                                            <br />
                                            <br />
                                            <h4
                                                onClick={changeContent.bind(null, 'FAQ')}
                                                style={{ display: 'inline', cursor: 'pointer' }}
                                            >
                                                FAQs and Help
                                            </h4>
                                            <p>
                                                Learn about video visits, how to schedule and join,
                                                and find help.
                                            </p>

                                            <h4
                                                onClick={changeContent.bind(null, 'GetApp')}
                                                style={{ display: 'inline', cursor: 'pointer' }}
                                            >
                                                Get the Alignment Health Plan App
                                            </h4>
                                            <p>
                                                Download our app for easy access to your video
                                                visit.
                                            </p>
                                        </Grid>

                                        <Grid
                                            item
                                            md={5}
                                            className={styles['paperContainer']}
                                        ></Grid>
                                    </Grid>
                                </Container>
                            </div>
                        </div>
                        <div className={styles['imageLoader']}>
                            <img src={androidScreenshot} alt="androidScreenshot" />
                            <img src={iosScreenshot} alt="iosScreenshot" />
                        </div>
                    </>
                )}
                {contentSection && (
                    <a
                        href="/TelehealthTest"
                        onClick={(e) => {
                            e.preventDefault();
                            changeContent();
                        }}
                        className={`${styles.goToButton} ${styles.back}`}
                    >
                        Go back to Tech Check page
                    </a>
                )}
                {contentSection === 'FAQ' && (
                    <div className={styles['VCT-additional-details-container']}>
                        <div className={styles['VCT-FAQ-container']}>
                            <section className={styles['VCT-test-left-text']}>
                                <h1>Video Visits FAQs</h1>
                            </section>
                            <div className={styles['VCT-tests']}>
                                <Container>
                                    <Grid
                                        container
                                        className={styles['details-container']}
                                        justifyContent="space-between"
                                        style={{ flexWrap: 'nowrap' }}
                                    >
                                        <p className={styles['quoted-line']}>
                                            &#8212; Scheduling a virtual appointment &#8212;
                                        </p>
                                        <h5>How to schedule a virtual appointment?</h5>
                                        <p>You can schedule a virtual appointment two ways:</p>
                                        <p>
                                            1. Call the Virtual Care Center, available 24 hours/7
                                            days a week.
                                        </p>
                                        <ul>
                                            <li>
                                                All Alignment Health Plan members
                                                <br />
                                                <strong>
                                                    1-833-AHC-ACCESS (833-242-2223) TTY 711,
                                                </strong>
                                            </li>
                                        </ul>
                                        <p>
                                            2. Log in to the Alignment Health Plan Member Portal and
                                            schedule an appointment.
                                        </p>
                                        <ul>
                                            <li>
                                                Go to{' '}
                                                <a
                                                    href="https://members.alignmenthealthplan.com"
                                                    target="_new"
                                                >
                                                    https://members.alignmenthealthplan.com
                                                </a>{' '}
                                                and sign into your secure online account.
                                            </li>
                                            <li>
                                                Click <strong>Get Virtual Care</strong> tile on the
                                                home page to be directed to your Virtual Care page
                                                &#8211; you should see Your Doctor is In at the top
                                                of the screen.
                                            </li>
                                            <li>
                                                Click Schedule An Appointment or Get 24/7 Care
                                                (Click Get 24/7 Care if you need to be seen right
                                                away)
                                                <img
                                                    className={styles['screenshot']}
                                                    src={getVirtualCareScreenshot}
                                                    alt="getVirtualCareScreenshot"
                                                />
                                            </li>
                                            <li>
                                                Follow the next steps in the portal to book your
                                                phone or video appointment.
                                                <ul style={{ listStyleType: 'disc' }}>
                                                    <li>
                                                        You can schedule a visit as soon as a next
                                                        appointment is available or on a specific
                                                        date and preferred window of time.
                                                    </li>
                                                    <li>
                                                        You will need to provide an email address
                                                        and a mobile number to schedule a virtual
                                                        visit.
                                                    </li>
                                                    <li>
                                                        You can request a translator/interpreter
                                                        with a preferred language for your virtual
                                                        visit.
                                                    </li>
                                                    <li>
                                                        You can add any notes you&#39;d like to
                                                        share with your provider.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <br />
                                        <h5>
                                            What happens after scheduling a virtual appointment?
                                        </h5>
                                        <p>
                                            You will receive a confirmation via email and SMS text
                                            message{' '}
                                            <strong>
                                                as soon as your appointment is scheduled by the
                                                Alignment Virtual Care Team
                                            </strong>
                                            . The email confirmation will contain information that
                                            will help you prepare for an optimal telehealth
                                            experience with the provider. You will also receive a
                                            confirmation via email and text 2 days prior to the
                                            appointment (if applicable).
                                        </p>
                                        <p>You will also receive:</p>
                                        <ul>
                                            <li>
                                                A confirmation via email and text 2 days prior to
                                                the appointment, if applicable
                                            </li>
                                            <li>
                                                An email notification and text message with a link
                                                to join the meeting{' '}
                                                <strong>15 minutes prior</strong> to the start time
                                                of the appointment
                                            </li>
                                        </ul>
                                        <br />
                                        <h5>How to reschedule or cancel virtual appointments?</h5>
                                        <p>
                                            <strong>Canceling your virtual appointment</strong>
                                            <br />
                                            You can cancel your virtual appointment in the Alignment
                                            Health Plan Member Portal by logging in to{' '}
                                            <a href="https://members.alignmenthealthplan.com">
                                                https://members.alignmenthealthplan.com
                                            </a>{' '}
                                            or calling dedicated toll-free phone number:{' '}
                                            <strong>1-833-AHC-ACCESS (833-242-2223) TTY 711</strong>{' '}
                                            (All Alignment Health Plan members)
                                        </p>
                                        <p>
                                            <strong>Rescheduling your virtual appointment</strong>
                                            <br />
                                            You can also reschedule appointment in Alignment Health
                                            Plan Member Portal at{' '}
                                            <a href="https://members.alignmenthealthplan.com">
                                                https://members.alignmenthealthplan.com
                                            </a>
                                            . To reschedule your virtual appointment, you should
                                            first cancel the upcoming appointment in the Member
                                            Portal and then create a new virtual appointment. You
                                            can also call dedicated toll-free phone number:{' '}
                                            <strong>1-833-AHC-ACCESS (833-242-2223) TTY 711</strong>
                                            , (All Alignment Health Plan members)
                                        </p>
                                        <hr />
                                        <p className={styles['quoted-line']}>
                                            &#8212; Before the appointment &#8212;
                                        </p>
                                        <h5>What do I need to do before my virtual appointment?</h5>
                                        <p>
                                            We want you to have the best virtual care experience
                                            possible. Here are a few tips to help you prepare for
                                            your appointment.
                                        </p>
                                        <p>
                                            <strong>1. Please arrive on time</strong>
                                            <br />
                                            Plan to log in or call in to your virtual appointment 15
                                            minutes before your scheduled time to make sure you have
                                            everything you need before starting.
                                        </p>
                                        <p>
                                            <strong>
                                                2. Make sure you have the right technology
                                            </strong>
                                            <br />
                                            For a video appointment, you will need a smartphone,
                                            tablet, or computer with built-in camera to connect to
                                            your virtual appointment. Have a strong network
                                            connection or a high-speed wi-fi connection.
                                        </p>
                                        <p>
                                            <strong>
                                                3. Have your at-home medical equipment handy
                                            </strong>
                                            <br />
                                            We encourage you to have a thermometer, bathroom scale,
                                            and blood pressure monitor for your virtual visit.
                                            Alignment Health Plan members can use their monthly
                                            over-the-counter allowance to order this equipment at{' '}
                                            <a
                                                href="https://NationsOTC.com/Alignment"
                                                target="_new"
                                            >
                                                NationsOTC.com/Alignment
                                            </a>
                                            .
                                        </p>
                                        <p>
                                            <strong>4. Test your audio and video</strong>
                                            <br />
                                            For the best audio quality, please use headphones or
                                            earbuds with a microphone. For a video appointment,
                                            ensure your browser settings have allowed access to your
                                            camera and microphone.{' '}
                                            <a
                                                href="/TelehealthTest"
                                                className={styles.goToButton}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    changeContent();
                                                }}
                                            >
                                                Test your audio and video
                                            </a>
                                            .
                                        </p>
                                        <p>
                                            <strong>
                                                5. Have your medication information ready
                                            </strong>
                                            <br />
                                            Keep a list of the names and doses of your medications
                                            ready so you can ask the right questions and request the
                                            correct refills.
                                        </p>
                                        <p>
                                            <strong>
                                                6. Prepare questions for your virtual provider
                                            </strong>
                                            <br />
                                            To make the most of your time with your virtual
                                            provider, make a list of the questions you want your
                                            provider to answer during your appointment.
                                        </p>
                                        <hr />
                                        <p className={styles['quoted-line']}>
                                            &#8212; Joining a virtual appointment &#8212;
                                        </p>
                                        <h5>How do I join an appointment?</h5>
                                        <p>
                                            You can join a virtual appointment by clicking the
                                            virtual appointment from your computer or your
                                            smartphone.
                                        </p>
                                        <ul>
                                            <li>
                                                <strong>
                                                    Joining from the email or SMS text message link
                                                </strong>
                                                <br />
                                                You will receive an email notification and SMS text
                                                message with a link to join the meeting{' '}
                                                <strong>15 minutes prior</strong> to the start time
                                                of the appointment.
                                                <br />
                                                <br />
                                                <ol>
                                                    <li>
                                                        Click the <strong>Join Now</strong> link in
                                                        the email notification or the link in the
                                                        SMS text message
                                                    </li>
                                                    <li>
                                                        You may see a browser pop-up requesting
                                                        permission to access your camera and
                                                        microphone. Click <strong>Allow</strong> in
                                                        the pop-up.
                                                    </li>
                                                    <li>
                                                        If you are the first one to join the meeting
                                                        room, you should see a welcome message like
                                                        below:
                                                        <br />
                                                        <img
                                                            className={styles['screenshot']}
                                                            src={joinedVVScreenshot}
                                                            alt="joinedVVScreenshot"
                                                        />
                                                        <br />
                                                        Once the provider joins the meeting room,
                                                        your virtual visit will begin.
                                                        <br />
                                                        <br />
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <strong>
                                                    Joining from the Alignment Health Plan Member
                                                    Portal
                                                </strong>
                                                <br />
                                                <ol>
                                                    <li>
                                                        Go to{' '}
                                                        <a
                                                            href="https://members.alignmenthealthplan.com"
                                                            target="_new"
                                                        >
                                                            https://members.alignmenthealthplan.com
                                                        </a>{' '}
                                                        and sign into your secure online account
                                                        from your computer or smartphone internet
                                                        browser (Google Chrome is recommended for
                                                        best performance).
                                                        <br />
                                                        <br />
                                                    </li>
                                                    <li>
                                                        Click <strong>Get Virtual Care</strong> to
                                                        be directed to your Virtual Care page - you
                                                        should see Your Doctor is In at the top of
                                                        the screen. In this page, the{' '}
                                                        <strong>Upcoming Appointments</strong>{' '}
                                                        section displays your upcoming appointment
                                                        card.
                                                        <br />
                                                        <br />
                                                    </li>
                                                    <li>
                                                        Click the video icon{' '}
                                                        <img
                                                            src={cameraIconScreenshot}
                                                            alt="cameraIconScreenshot"
                                                        />{' '}
                                                        in the appointment card when you are ready
                                                        to join the appointment. Note that the video
                                                        icon is only clickable{' '}
                                                        <strong>15 minutes before</strong> your
                                                        appointment.
                                                        <ul style={{ listStyleType: 'none' }}>
                                                            <li>
                                                                You will see a browser pop-up
                                                                requesting permission to access your
                                                                camera and microphone. Click{' '}
                                                                <strong>Allow</strong> in the pop-up
                                                                <br />
                                                                <img
                                                                    className={styles['screenshot']}
                                                                    src={PermissionsScreenshot}
                                                                    alt="PermissionsScreenshot"
                                                                />{' '}
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        If you are the first one to join the meeting
                                                        room, you should see a welcome message like
                                                        this:
                                                        <ul style={{ listStyleType: 'none' }}>
                                                            <li>
                                                                <img
                                                                    className={styles['screenshot']}
                                                                    src={joinedVVScreenshot}
                                                                    alt="joinedVVScreenshot"
                                                                />
                                                                <br />
                                                                Once the provider joins the meeting
                                                                room, your virtual visit will begin.
                                                                <br />
                                                                <br />
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ul>
                                        <hr />
                                        <p className={styles['quoted-line']}>
                                            &#8212; Troubleshooting for virtual visits &#8212;
                                        </p>
                                        <h5>
                                            I am unable to join the virtual appointment. What do I
                                            do?
                                        </h5>
                                        <p>
                                            1. <strong>Rejoin the appointment</strong>. If you are
                                            signed into the Alignment Health Plan Member Portal,
                                            click the video icon{' '}
                                            <img
                                                src={cameraIconScreenshot}
                                                alt="cameraIconScreenshot"
                                            />{' '}
                                            in the appointment card in the Member Portal home page,
                                            or click the appointment link in the SMS text message or
                                            email.
                                        </p>
                                        <p>
                                            2.{' '}
                                            <strong>Check to your Wi-Fi or network signal</strong>.
                                            Wi-Fi is recommended if possible. Move closer to your
                                            Wi-Fi router/modem or an area with a stronger network
                                            signal.
                                        </p>

                                        <div>
                                            3.{' '}
                                            <strong>
                                                Call dedicated toll-free phone number for support,
                                            </strong>{' '}
                                            if you are still unable to join,
                                            <ul>
                                                <li>
                                                    All Alignment Health Plan members
                                                    <br />
                                                    <strong>
                                                        1-833-AHC-ACCESS (833-242-2223) TTY 711,
                                                    </strong>
                                                </li>
                                            </ul>
                                        </div>
                                        <br />
                                        <br />
                                        <h5>
                                            I was disconnected from the appointment. What do I do?
                                        </h5>
                                        <p>
                                            Click{' '}
                                            <img
                                                src={cameraIconScreenshot}
                                                alt="cameraIconScreenshot"
                                            />{' '}
                                            again in the appointment card in Alignment Health Plan
                                            Member Portal and you should be connected back to the
                                            meeting room or click the appointment link in the SMS
                                            text message or email.
                                        </p>
                                        <br />
                                        <br />
                                        <h5>I am having audio issues. What do I do?</h5>
                                        <p>
                                            If you can’t hear the provider, the provider can’t hear
                                            you, the audio is choppy, the audio has dropped, or you
                                            are facing other audio issues, follow any of these steps
                                            to troubleshoot: Rejoin the appointment. If the provider
                                            is in the meeting room, let them know that you will be
                                            rejoining the appointment.
                                        </p>
                                        <div>
                                            <strong>How to rejoin an appointment:</strong>
                                            <ul style={{ listStyleType: 'none' }}>
                                                <li>
                                                    — For <strong>&#39;video&#39;</strong> calls
                                                    <br />
                                                    If you are signed into the Alignment Health Plan
                                                    Member Portal, click the video icon{' '}
                                                    <img
                                                        src={cameraIconScreenshot}
                                                        alt="cameraIconScreenshot"
                                                    />{' '}
                                                    in the appointment card in the Member Portal
                                                    home page, or click the appointment link in the
                                                    SMS text message or email.
                                                </li>
                                                <li>
                                                    — For <strong>‘audio only’</strong> calls
                                                    <br />
                                                    Call the meeting number that was sent to you via
                                                    email <strong>15 minutes prior</strong> to the
                                                    appointment start time.
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    Make sure the computer or phone speakers are not
                                                    muted or are set to an optimal volume.
                                                </li>
                                                <li>
                                                    Ensure your browser settings on your phone or
                                                    computer have allowed access to your microphone.
                                                    Turn on app permissions for microphone in
                                                    Windows 10, and for Mac.
                                                </li>

                                                <li>
                                                    If you are using headphones, make sure they are
                                                    working and are connected to the correct port.
                                                </li>
                                                <li>Make sure you are not muted</li>
                                                <li>
                                                    Make sure Wi-Fi or network signal is strong. If
                                                    possible, move closer to your Wi-Fi
                                                    router/modem. Consider connecting to the
                                                    Internet using a wired connection (using an
                                                    Ethernet cable).
                                                </li>
                                                <li>
                                                    Close any other browser windows, running
                                                    programs, or applications that are not necessary
                                                    for the virtual appointment.
                                                </li>
                                                <li>
                                                    If there is background noise, move to a quieter
                                                    location.
                                                </li>
                                                <li>
                                                    Make sure you are using the recommended
                                                    browsers–{' '}
                                                    <strong>
                                                        Google Chrome (for best performance)
                                                    </strong>{' '}
                                                    or Microsoft Edge (Windows), Safari (iOS),
                                                    Google Chrome or Mozilla Firefox (Android).
                                                </li>
                                                <li>
                                                    If you are using a laptop or personal computer,
                                                    try restarting the device.
                                                </li>
                                                <li>
                                                    If you are still unable to join, you can also
                                                    call dedicated toll-free phone number to get
                                                    support:{' '}
                                                    <strong>
                                                        1-833-AHC-ACCESS (833-242-2223) TTY 711
                                                    </strong>{' '}
                                                    (All Alignment Health Plan members)
                                                </li>
                                            </ul>
                                        </div>
                                        <br />
                                        <br />
                                        <h5>I am having video issues. What do I do?</h5>
                                        <p>
                                            If you are unable to see the provider in the
                                            appointment, the provider can’t see you, there is blurry
                                            or distorted video, or other video issues, follow these
                                            steps to troubleshoot:
                                        </p>
                                        <ul>
                                            <li>
                                                Rejoin the appointment. If the provider is in the
                                                meeting room, let them know that you will be
                                                rejoining the appointment.
                                                <br />
                                                <strong>How to rejoin an appointment:</strong>
                                                <ul style={{ listStyleType: 'none' }}>
                                                    <li>
                                                        — For <strong>&#39;video&#39;</strong> calls
                                                        <br />
                                                        If you are signed into the Alignment Health
                                                        Plan Member Portal, click the video icon{' '}
                                                        <img
                                                            src={cameraIconScreenshot}
                                                            alt="cameraIconScreenshot"
                                                        />{' '}
                                                        in the appointment card in the Member Portal
                                                        home page, or click the appointment link in
                                                        the SMS text message or email.
                                                    </li>
                                                    <li>
                                                        — For <strong>‘audio only’</strong> calls
                                                        <br />
                                                        Call the meeting number that was sent to you
                                                        via email <strong>
                                                            15 minutes prior
                                                        </strong>{' '}
                                                        to the appointment start time.
                                                    </li>
                                                </ul>
                                                <br />
                                            </li>
                                            <li>
                                                Ensure your browser settings on your device have
                                                allowed access to your camera
                                            </li>
                                            <li>
                                                Make sure Wi-Fi or network signal is strong. If
                                                possible, move closer to your Wi-Fi router/modem.
                                                Consider connecting to the Internet using a wired
                                                connection (using an Ethernet cable).
                                            </li>
                                            <li>
                                                Close any other browser windows, running programs,
                                                or applications that are not necessary for the
                                                virtual appointment.
                                            </li>
                                            <li>
                                                Make sure you are using the recommended browsers –{' '}
                                                <strong>
                                                    Google Chrome (for best performance)
                                                </strong>{' '}
                                                or Microsoft Edge (Windows), Safari (iOS), or
                                                Mozilla Firefox (Android).
                                            </li>
                                            <li>Restart the device.</li>
                                            <li>
                                                If you are still unable to join, you can also call
                                                dedicated toll-free phone number to get support:{' '}
                                                <strong>
                                                    1-833-AHC-ACCESS (833-242-2223) TTY 711
                                                </strong>{' '}
                                                (All Alignment Health Plan members)
                                            </li>
                                        </ul>
                                        <hr />
                                        <p className={styles['quoted-line']}>
                                            &#8212; Video Visit FAQs for AVA (HMO) Alignment members
                                            only &#8212;
                                        </p>
                                        <h5>How to schedule a virtual appointment?</h5>
                                        <p>You can schedule a virtual appointment two ways:</p>
                                        <ol>
                                            <li>
                                                Call this dedicated toll-free phone number:{' '}
                                                <strong>
                                                    1-833-AHC-ACCESS (833-242-2223) TTY 711
                                                </strong>{' '}
                                                (All Alignment Health plan members),{' '}
                                            </li>
                                            <li>
                                                Log in to the Alignment Health Plan Member Portal
                                                and schedule an appointment
                                                <ul style={{ listStyleType: 'disc' }}>
                                                    <li>
                                                        Go to{' '}
                                                        <a
                                                            href="https://members.alignmenthealthplan.com"
                                                            target="_new"
                                                        >
                                                            https://members.alignmenthealthplan.com
                                                        </a>{' '}
                                                        and sign into your secure online account.
                                                    </li>
                                                    <li>
                                                        Click Get Virtual Care tile on the home page
                                                        to be directed to your Virtual Care page –
                                                        you should see Your Doctor is In at the top
                                                        of the screen.
                                                    </li>
                                                    <li>
                                                        Click See Your PCP.
                                                        <br />
                                                        <img
                                                            className={styles['screenshot']}
                                                            src={yourDoctorIsInScreenshot}
                                                            alt="yourDoctorIsInScreenshot"
                                                        />
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                Follow the next steps in the portal to book your
                                                phone or video appointment.
                                                <ul style={{ listStyleType: 'disc' }}>
                                                    <li>
                                                        You can schedule a visit as soon as a next
                                                        appointment is available or on a specific
                                                        date and preferred window of time
                                                    </li>
                                                    <li>
                                                        You will need to provide an email address
                                                        and a mobile number to schedule a virtual
                                                        visit
                                                    </li>
                                                    <li>
                                                        You can request a translator/interpreter
                                                        with a preferred language for your virtual
                                                        visit
                                                    </li>
                                                    <li>
                                                        You can add any notes you’d like to share
                                                        with your provider
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                        <br />
                                        <br />
                                        <h5>
                                            What happens after scheduling a virtual appointment?
                                        </h5>
                                        <p>
                                            You will receive a confirmation via email and SMS text
                                            message as soon as you schedule your appointment. The
                                            email confirmation will contain information that will
                                            help you prepare for an optimal telehealth experience
                                            with the provider.
                                        </p>
                                        <p>You will also receive:</p>
                                        <ul>
                                            <li>
                                                A confirmation via email and text 2 days prior to
                                                the appointment (if applicable)
                                            </li>
                                            <li>
                                                An email notification and text message with a link
                                                to join the meeting 15 minutes prior to the start
                                                time of the appointment
                                            </li>
                                        </ul>
                                        <hr />
                                        <p className={styles['quoted-line']}>
                                            &#8212; Additional FAQs &#8212;
                                        </p>
                                        <h5>Who do I contact for support?</h5>
                                        <p>
                                            For appointment scheduling, technical support,
                                            questions, or assistance related to virtual
                                            appointments, please call the dedicated toll-free
                                            number, 24 hours/ 7 days a week:
                                        </p>
                                        <ul>
                                            <li>
                                                All Alignment Health Plan members
                                                <br />
                                                <strong>
                                                    1-833-AHC-ACCESS (833-242-2223) TTY 711,
                                                </strong>
                                            </li>
                                        </ul>
                                        <br />
                                        <h5>Are there language services available?</h5>
                                        <p>
                                            Yes, you can request a translator/interpreter when you
                                            schedule your visit and indicate a preferred language.
                                        </p>
                                        <br />
                                        <h5>
                                            Can my family member or caregiver join my virtual
                                            appointment?
                                        </h5>
                                        <p>
                                            Yes, a family member or caregiver can join your virtual
                                            appointment with you and your provider. You can share
                                            the link to join the virtual appointment or audio-only
                                            number once you receive your email and SMS text message
                                            notification <strong>15 minutes</strong> prior to your
                                            appointment time.
                                        </p>
                                        <br />
                                        <h5>
                                            Do I need to download an app on my smartphone to have a
                                            virtual appointment with my provider?
                                        </h5>
                                        <p>
                                            No, you can join a virtual appointment without
                                            downloading the Alignment Health application – you can
                                            click on the appointment link on your computer or
                                            smartphone to connect to the virtual appointment
                                            directly.
                                        </p>
                                    </Grid>
                                    <a
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}
                                        className={`${styles.goToButton} ${styles.top}`}
                                    >
                                        Scroll to top &#8593;
                                    </a>
                                </Container>
                            </div>
                        </div>
                    </div>
                )}
                {contentSection === 'GetApp' && (
                    <div className={styles['VCT-additional-details-container']}>
                        <div className={styles['VCT-FAQ-container']}>
                            <section className={styles['VCT-test-left-text']}>
                                <h1>Get the Alignment Health Plan App</h1>
                            </section>
                            <div className={styles['VCT-tests']}>
                                <Container>
                                    <Grid
                                        container
                                        className={styles['container']}
                                        justifyContent="space-between"
                                    >
                                        <Grid item md={5}>
                                            <h4>
                                                <a
                                                    href="https://play.google.com/store/apps/details?id=com.ahcusa.ahcmemberapp"
                                                    target="_new"
                                                >
                                                    Download app on Google Play Store
                                                </a>
                                            </h4>
                                            <br />
                                            <br />
                                            <img
                                                className={styles['screenshot']}
                                                src={androidScreenshot}
                                                alt="androidScreenshot"
                                            />
                                        </Grid>

                                        <Grid item md={5}>
                                            <h4>
                                                <a
                                                    href="https://apps.apple.com/us/app/alignment-health-plan/id1447430927"
                                                    target="_new"
                                                >
                                                    Download app on the App Store
                                                </a>
                                            </h4>
                                            <br />
                                            <br />
                                            <img
                                                className={styles['screenshot']}
                                                src={iosScreenshot}
                                                alt="iosScreenshot"
                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                            </div>
                        </div>
                    </div>
                )}

                <div className={styles['footer-bar']}>
                    <div className={styles['footer-blue-bar']}>
                        <span>
                            &#169; {currentYear} Alignment Health Plan. All Rights Reserved. Privacy
                            Policy & Terms of Use.
                            <br />
                            Last Updated June 7, 2022
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TelehealthTest;
