import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faVideo, faPhone } from '@fortawesome/free-solid-svg-icons';
import useParticipants from '../../hooks/useParticipants';
import useRoom from '../../hooks/useRoom';
import useTracks from '../../hooks/useTracks';
import useTrackEnabled from '../../hooks/useTrackEnabled';
import useDataTrack from '../../hooks/useDataTrack';
import HostParticipant from './HostParticipant';
import Participants from './Participants';
import ParticipantsInfo from './ParticipantsInfo';
import ConversationRoom from '../ConversationRoom/ConversationRoom';
import { createLocalVideoTrack } from 'twilio-video';
import { isAudioAppointment } from '../../common/utility';
import Feature from '../../hoc/Feature';
import { conversation$ } from '../../services/subjects';
import roomService from '../../services/roomService';
import { conversationService } from '../../services/conversationService';

import styles from './RoomBody.module.scss';

const INITIAL_STATE = {
    muted: false,
    videoOff: false,
};

function RoomBody({
    appointment,
    onLeave,
    conversationsToken,
    conversationSid,
    participantName,
    participantIdentity,
    featureFlags,
}) {
    const [subscriptions, setSubscriptions] = useState([]);

    const [conversationsAccessToken, setConversationsAccessToken] = useState(conversationsToken);

    const [{ muted, videoOff }, setState] = useState(INITIAL_STATE);
    const { room } = useRoom();
    const { tracks } = useTracks(room?.localParticipant, false, isAudioAppointment(appointment));
    const isAudioEnabled = useTrackEnabled(tracks, 'audio');
    const { hostParticipant, participants } = useParticipants(appointment);
    const emit = useDataTrack();

    useEffect(() => {
        return () => {
            subscriptions.forEach((sub) => sub.unsubscribe());
        };
    }, []);

    function getTotalParticipants() {
        return (appointment?.optionalAttendees || []).length + 2;
    }

    function getParticipantsCount() {
        return participants.length;
    }

    async function handleTurnOffCameraClick() {
        if (videoOff) {
            const videoTrack = await createLocalVideoTrack();
            room.localParticipant.publishTrack(videoTrack);
            setState((prevState) => ({
                ...prevState,
                videoOff: false,
            }));
            return;
        }
        room.localParticipant.tracks.forEach((publication) => {
            if (publication.track?.kind === 'video') {
                room.localParticipant.emit('localTrackUnsubscribed', publication.track);
                publication.track.stop();
                publication.unpublish();
                room.localParticipant.emit('localTrackUnpublished', publication);
                setState((prevState) => ({
                    ...prevState,
                    videoOff: true,
                }));
            }
        });
    }

    async function handleMuteClick() {
        room.localParticipant.tracks.forEach((publication) => {
            if (publication.track?.kind === 'audio') {
                if (muted) {
                    publication.track.enable();
                } else {
                    publication.track.disable();
                }
            }
        });
    }

    useEffect(() => {
        let nextMuted = true;
        if (isAudioEnabled) {
            nextMuted = false;
        }

        setState((prevState) => ({
            ...prevState,
            muted: nextMuted,
        }));
    }, [isAudioEnabled]);

    const onConversationCreate = (conversation) => {
        const message = {
            eventKind: 'conversation',
            params: {
                conversationSid: conversation.sid,
                identity: room.localParticipant.identity,
            },
        };
        emit(room.localParticipant, 'conversation', message);
        updateVirtualAppointment(conversation.sid);
        conversation$.next(conversation);
    };

    const updateVirtualAppointment = (conversationSid) => {
        const updateApptSub = roomService.update$(appointment.id, conversationSid).subscribe();
        setSubscriptions([...subscriptions, updateApptSub]);
    };

    const onConversationJoin = (conversation) => {
        conversation$.next(conversation);
    };

    const handleConversationsTokenAboutToExpire = () => {
        const getTokenSub = conversationService.getToken$(participantIdentity).subscribe({
            next: (response) => {
                setConversationsAccessToken(response.response.token);
            },
        });

        setSubscriptions([...subscriptions, getTokenSub]);
    };

    const uploadMessageFile = async (file) => {
        return fetch(`api/conversations/uploadFile`, {
            method: 'POST',
            body: file,
        })
            .then((res) => res.json())
            .then((data) => {
                return data.fileId;
            });
    };

    return (
        <section className={styles['room-body']}>
            <div>
                <article className={styles.participants}>
                    {hostParticipant && (
                        <HostParticipant participant={hostParticipant} appointment={appointment} />
                    )}
                    <Participants
                        hostParticipant={hostParticipant}
                        participants={participants}
                        appointment={appointment}
                    />
                </article>
                <article className={styles.actions}>
                    <div
                        className={`${muted ? styles.inactive : ''} ${styles.action}`}
                        onClick={handleMuteClick}
                    >
                        <FontAwesomeIcon icon={faMicrophone} />
                    </div>
                    {(!isAudioAppointment(appointment) || null) && (
                        <div
                            className={`${videoOff ? styles.inactive : ''} ${styles.action}`}
                            onClick={handleTurnOffCameraClick}
                        >
                            <FontAwesomeIcon icon={faVideo} />
                        </div>
                    )}
                    <div className={`${styles.inactive} ${styles.action}`} onClick={onLeave}>
                        <FontAwesomeIcon icon={faPhone} />
                    </div>
                </article>
                <ParticipantsInfo
                    appointment={appointment}
                    participants={participants.filter((p) => p)}
                />
            </div>
            {/* <article className={styles.chatroom}>
                <Feature featureFlags={featureFlags} name="Conversations">
                    <ConversationRoom
                        participantName={participantName}
                        participantIdentity={participantIdentity}
                        conversationsToken={conversationsAccessToken}
                        conversationSid={conversationSid}
                        conversationFriendlyName={`Telehealth Chat`}
                        conversationAttributes={{
                            memberId: appointment.memberId,
                            providerNpi: appointment.providerNPI,
                        }}
                        onConversationCreate={onConversationCreate}
                        onConversationJoin={onConversationJoin}
                        handleTokenAboutToExpire={handleConversationsTokenAboutToExpire}
                        uploadMessageFile={uploadMessageFile}
                    />
                </Feature>
            </article> */}
        </section>
    );
}

RoomBody.displayName = RoomBody.name;

export default RoomBody;
