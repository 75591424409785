import { ajax } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { providerAuthToken$ } from './subjects';
import { getDeviceOS } from '../common/utility';

const roomService = {
    get$: (id, connectId) =>
        ajax({
            method: 'GET',
            url: `api/video/${id}/${connectId}`,
            headers: {
                'Content-Type': 'application/json',
            },
        }),
    update$: (id, conversationSid) =>
        ajax({
            method: 'POST',
            url: `api/video/update`,
            headers: {
                'Content-Type': 'application/json',
            },
            body: { appointmentId: id, conversationSid: conversationSid },
            dataType: 'json',
        }),
    post$: (roomName, memberId) =>
        providerAuthToken$.pipe(
            switchMap((token) =>
                ajax({
                    method: 'POST',
                    url: `api/video/CreateVideoRoom?roomName=${roomName}&memberId=${memberId}`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
            )
        ),
    getTranslatorLink$: (appointmentId, memberId) => {
        return providerAuthToken$.pipe(
            switchMap((token) =>
                ajax({
                    url: `/api/video/TranslatorLink?appointmentId=${appointmentId}&memberId=${memberId}`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                })
            )
        );
    },
    getToken$: (id, connectId) =>
        ajax({
            method: 'GET',
            url: `api/video/token/${id}/${connectId}`,
            headers: {
                'Content-Type': 'application/json',
            },
        }),
    getProviderToken$: (appointmentId, memberId) =>
        providerAuthToken$.pipe(
            switchMap((token) =>
                ajax({
                    method: 'GET',
                    url: `api/video/AppointmentToken/${appointmentId}/${memberId}`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
            )
        ),
    trackEvent$: (id, connectId, eventName) =>
        ajax({
            method: 'POST',
            url: `api/video/track`,
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                appointmentId: id,
                participantId: connectId,
                visitEvent: eventName,
                device: getDeviceOS(),
            },
        }),
    notifyProvider$: (id) =>
        ajax({
            method: 'POST',
            url: `api/video/notifyprovider/${id}`,
            headers: {
                'Content-Type': 'application/json',
            },
        }),
};

export default roomService;
