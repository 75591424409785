import React, { useEffect, useRef } from 'react';
import useRoom from '../../hooks/useRoom';
import useTrackEnabled from '../../hooks/useTrackEnabled';

import styles from './Tracks.module.scss';

const HostTrack = ({ tracks, participant }) => {
    const tracksRef = useRef(null);
    const isVideoEnabled = useTrackEnabled(tracks);
    const room = useRoom();

    useEffect(() => {
        const el = tracksRef.current;
        const oldTracks = tracks.slice();
        oldTracks.forEach((t) => {
            if (t.kind !== 'data' && t.kind !== 'audio') {
                el.appendChild(t.attach());
                el.appendChild(document.createElement('div'));
            }
        });
        return () => {
            if(el) {
                el.innerHTML = '';
            }
        };
    }, [tracks]);

    return <div className={`${styles.tracks} ${!isVideoEnabled ? styles.disabled : ''}`} ref={tracksRef}></div>;
};

HostTrack.displayName = HostTrack.name;

export default HostTrack;
