import React from 'react';
import PropTypes from 'prop-types';
import Participant from './Participant';
import MemberParticipant from './MemberParticipant';

import styles from './Participants.module.scss';

const Participants = ({ participants, localParticipant, appointment }) => {
    function isMember(participant) {
        return participant.identity === appointment.memberConnectionId;
    }

    const [memberParticipant, anyOtherParticipants] = (participants || []).reduce(
        (participantSections, nextParticipant) => {
            if (isMember(nextParticipant)) {
                participantSections[0] = nextParticipant;
            } else if (localParticipant == nextParticipant) {
                participantSections[1].push(nextParticipant);
            } else {
                participantSections[1].unshift(nextParticipant);
            }
            return participantSections;
        },
        [null, []]
    );

    return (
        <div className={styles.participants}>
            <div className={styles['member-participant']}>
                {memberParticipant && (
                    <MemberParticipant participant={memberParticipant} appointment={appointment} />
                )}
                {!memberParticipant && <h2>Member hasn&#39;t joined yet</h2>}
            </div>

            <div className={styles['any-other-participants']}>
                {anyOtherParticipants.map((participant) => (
                    <Participant
                        key={participant.sid}
                        appointment={appointment}
                        participant={participant}
                    />
                ))}
            </div>
        </div>
    );
};

Participants.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.object),
};
Participants.defaultProps = {
    participants: [],
};

export default Participants;
